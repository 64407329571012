/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useCallback, useRef } from "react";
import { isDefined } from "@proximie/shared-utils";
import useSWRInfinite from "swr/infinite";
import {
  ResponseData,
  QueryParams,
  Add,
  Del,
  Get,
  Update,
  AddParams,
  DelParams,
  UpdateParams,
  MutateOne,
  Filters,
  LoaderRefCallback,
} from "./useContinuousScroll.types";

export type Props<T> = {
  dataKey: string;
  get: Get<T>;
  add?: Add<T>;
  del?: Del;
  update?: Update<T>;
  revalidateFirstPage?: boolean;
  suspense?: boolean;
  limit?: number;
  filters?: Filters;
};

export type Return<T> = {
  data: T[];
  count: number;
  loading: boolean;
  hasMore: boolean;
  error: Error | null;
  reset: VoidFunction;
  loadMore: VoidFunction;
  add?: Add<T>;
  del?: Del;
  update?: Update<T>;
  mutateOne: MutateOne<T>;
  loaderRef: LoaderRefCallback;
};

export function useContinuousScroll<T>({
  dataKey,
  suspense = false,
  limit = 10,
  revalidateFirstPage = true,
  filters = {},
  get,
  add,
  del,
  update,
}: Props<T>): Return<T> {
  const loaderRef = useRef<Element | null>(null);

  const getKey = (
    pageIndex: number,
    previousPageData: ResponseData<T> | null,
  ): QueryParams | null => {
    // Does not fetch if its the last page
    if (pageIndex > 0 && previousPageData?.pageInfo.hasNext === false) {
      return null;
    }

    const params: QueryParams = {
      paginationType: "CURSOR",
      nextCursor:
        isDefined(previousPageData?.pageInfo) &&
        previousPageData?.pageInfo?.hasNext &&
        previousPageData?.pageInfo?.nextCursor
          ? previousPageData.pageInfo.nextCursor
          : undefined,
      limit: limit.toString(),
      sort_order: filters.sort_order ?? "DESC",
      ...filters,
    };

    return params;
  };

  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite<
    ResponseData<T>
  >(getKey, get, { suspense, revalidateFirstPage });

  const isFetching = isValidating && size > 0;

  const loadMore = useCallback(() => {
    setSize((size) => size + 1);
  }, [setSize, limit]);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !isFetching) {
        loadMore();
      }
    },
    [loadMore, isFetching],
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const setLoaderRef: LoaderRefCallback = useCallback(
    (node: Element | null) => {
      if (node !== null) {
        loaderRef.current = node;
        if (observer.current) {
          observer.current.observe(node);
        }
      } else if (loaderRef.current) {
        if (observer.current) {
          observer.current.unobserve(loaderRef.current);
        }
        loaderRef.current = null;
      }
    },
    [],
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    observer.current = new IntersectionObserver(handleObserver, option);

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [handleObserver]);

  const handleAdd = isDefined(add)
    ? async (params: AddParams) => {
        const response = await add(params);
        mutate();
        return response;
      }
    : undefined;

  const handleDel = isDefined(del)
    ? async (params: DelParams) => {
        await del(params);
        mutate();
      }
    : undefined;

  const handleUpdate = isDefined(update)
    ? async (params: UpdateParams) => {
        const response = await update(params);
        mutate();
        return response;
      }
    : undefined;

  const reset = () => {
    mutate(undefined, { revalidate: true });
    setSize(0);
  };

  const mutateOne = (
    identifierName: keyof T,
    identifierValue: string,
    newValue: Partial<T>,
  ) => {
    mutate(
      (currentData: ResponseData<T>[] | undefined) => {
        if (!currentData) return currentData;

        return currentData.map((dataItem) => {
          const updatedItem = dataItem[dataKey].map((item) =>
            item[identifierName] === identifierValue
              ? {
                  ...item,
                  ...newValue,
                }
              : item,
          );

          return {
            ...dataItem,
            [dataKey]: updatedItem,
          };
        });
      },
      { revalidate: false },
    );
  };

  return {
    data: data?.flatMap((d) => d[dataKey] ?? []) ?? [],
    count:
      data?.length && data[data.length - 1]?.pageInfo
        ? data[data.length - 1]?.pageInfo.count
        : 0,
    loading: (!error && !data) || isFetching,
    hasMore: data?.length ? data[data.length - 1]?.pageInfo?.hasNext : true,
    error: error,
    reset,
    add: handleAdd,
    del: handleDel,
    update: handleUpdate,
    mutateOne,
    loadMore,
    loaderRef: setLoaderRef,
  };
}
