import React from "react";
import EventEmitter from "events";
import { io, Socket } from "socket.io-client";
import { liveApiSockets } from "@proximie/dataregion-api";
import { environment } from "../../../environments/environment";
import SessionParams from "../../models/SessionParams";
import { pipe } from "fp-ts/lib/function";
import { fold } from "fp-ts/lib/Either";

type SetServerParams =
  | React.Dispatch<
      React.SetStateAction<liveApiSockets.MediaConnectionDetails | null>
    >
  | undefined;

const RECONNECT_TIMEOUT = 2000;
const GUARD_TIMEOUT = 30000;

class SocketIoClientWrapper extends EventEmitter {
  private socket?: Socket;
  private setServerParams: SetServerParams;
  private reconnectTimerId: ReturnType<typeof setTimeout> | null = null;
  private guardTimerId: ReturnType<typeof setTimeout> | null = null;
  private token = "";

  public get isConnected(): boolean {
    return this.socket?.connected || false;
  }

  private reconnect() {
    if (!this.guardTimerId) {
      this.guardTimerId = setTimeout(() => {
        // we've given it a go and we can't re-connect - so raise
        // an error to the user
        console.warn("Websocket guard timer expired");
        this.socket?.disconnect();
        this.emit("error", new Error("Failed to connect to websocket"));
        this.socket = undefined;
        this.guardTimerId = null;
        if (this.reconnectTimerId) {
          clearTimeout(this.reconnectTimerId);
          this.reconnectTimerId = null;
        }
      }, GUARD_TIMEOUT);
    }
    if (!this.reconnectTimerId) {
      this.reconnectTimerId = setTimeout(() => {
        console.log("Attempting reconnection");
        this.socket?.connect();
      }, RECONNECT_TIMEOUT);
    }
  }

  private isFatalError(fullMessage: string): boolean {
    // any errors that are not transient should be listed
    // here.  These errors should not cause a re-connect
    // since the same response will be expected.
    const fatalErrorMessages = [
      "Request failed with status code 401",
      "invalid signature",
      "PROFILE_NOT_COMPLETED",
      "invalid input syntax for type uuid:",
      "Media session not found with id:",
      "Session in incorrect state",
    ];
    return !!fatalErrorMessages.find((message: string): boolean =>
      fullMessage.includes(message),
    );
  }

  public init(sessionParams: SessionParams, setServerParams: SetServerParams) {
    const isLocalHost = environment.domain.includes("localhost");
    const dataRegionDomain = isLocalHost
      ? `${environment.domain}:2020`
      : environment.domain;
    const url = isLocalHost
      ? `ws://${dataRegionDomain}`
      : `wss://${sessionParams.endpoint}.${dataRegionDomain}`;

    const options = {
      auth: (cb: (data: object) => void) => cb({ token: this.token }),
      query: {
        mediaSessionId: sessionParams.mediaSessionId,
      },
      transports: ["websocket"],
      upgrade: false,
    };
    this.socket = io(url, options);

    this.setServerParams = setServerParams;

    this.socket?.on("connect", () => {
      console.log("CONNECT");
      if (this.guardTimerId) {
        clearTimeout(this.guardTimerId);
        this.guardTimerId = null;
      }
      if (this.reconnectTimerId) {
        clearTimeout(this.reconnectTimerId);
        this.reconnectTimerId = null;
      }
      this.emit("connected");
    });

    this.socket?.on("disconnect", (reason) => {
      console.log("DISCONNECT", reason);
      switch (reason) {
        case "io server disconnect":
          // this is likely to be an end session - don't reconnect
          this.emit("ended");
          break;
        case "io client disconnect":
          // we need to force a reconnection in this case
          this.reconnect();
          this.emit("disconnected", reason);
          break;
        default:
          // reconnection handled automatically
          this.emit("disconnected", reason);
      }
    });

    this.socket?.on("connect_error", (error) => {
      console.log("CONNECT_ERROR", error.message);
      if (this.isFatalError(error.message)) {
        this.socket?.disconnect();
        this.emit("error", error);
        this.socket = undefined;
      } else {
        // for any error attempt a re-connection after a brief period
        this.reconnect();
      }
    });

    this.socket?.io.on("reconnect", () => {
      console.log("RECONNECT");
    });

    this.socket?.on("error", (error: Error) => {
      console.log("ERROR", error);
      this.socket?.disconnect();
      this.emit("error", error);
      this.socket = undefined;
    });

    this.socket?.onAny(
      (
        name: string,
        notification: liveApiSockets.MediaSessionEventNotification,
      ): void => {
        const event =
          liveApiSockets.convertMediaSessionEventNotificationToMediaSessionEventObject(
            notification,
          );

        this.emit(
          name,
          event.detail,
          event.profileId,
          event.timestamp,
          event.sequenceNumber,
          event.userId,
        );
      },
    );

    this.socket?.on(
      liveApiSockets.MediaSessionEventType.broadcast,
      (notification: liveApiSockets.MediaSessionEventNotification) => {
        const event =
          liveApiSockets.convertMediaSessionEventNotificationToMediaSessionEventObject(
            notification,
          );

        const detail =
          event.detail as liveApiSockets.MediaSessionEventDetailsBroadcast;

        this.emit(
          SocketIoClientWrapper.createBroadcastName(detail.topic),
          detail.data,
          event.profileId,
          event.timestamp,
          event.sequenceNumber,
          event.userId,
        );
      },
    );
  }

  public destroy() {
    if (this.socket) {
      this.socket.off();
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  private log(
    name: string,
    details: liveApiSockets.MediaSessionEventDetails,
  ): void {
    if (name !== liveApiSockets.MediaSessionEventType.telestration) {
      // output the streamId is the event contains one
      if ((details as { streamId?: string }).streamId) {
        console.info(
          { streamId: (details as { streamId?: string }).streamId },
          `UX Event: ${name}`,
          details,
        );
      } else {
        console.info(`UX Event: ${name}`, details);
      }
    } else {
      const telestrationDetails =
        details as liveApiSockets.MediaSessionEventDetailsTelestration;
      // limit logging to the finalised telestrations
      if (
        telestrationDetails.command !== "moveCursor" &&
        telestrationDetails.command !== "drawSegment"
      ) {
        console.info(
          { streamId: telestrationDetails.streamId },
          `UX Event: ${name}`,
          details,
        );
      }
    }
  }

  public sendSync(
    name: string,
    details: liveApiSockets.MediaSessionEventDetails,
  ): void {
    this.log(name, details);
    this.socket?.emit(name, details);
  }

  public broadcastSync(topic: string, data: object): void {
    this.sendSync(liveApiSockets.MediaSessionEventType.broadcast, {
      topic,
      data,
    });
  }

  public sendAsync(
    name: string,
    details: liveApiSockets.MediaSessionEventDetails,
  ): Promise<liveApiSockets.MediaSessionEventResponseDetails> {
    return new Promise((resolve, reject) => {
      this.log(name, details);
      this.socket?.emit(
        name,
        details,
        (response: { status: string; detail: unknown }): void => {
          if (response.status === "success") {
            resolve(response.detail);
          } else {
            reject(response.detail);
          }
        },
      );
    });
  }

  public broadcastAsync(
    topic: string,
    data: object,
  ): Promise<liveApiSockets.MediaSessionEventResponseDetails> {
    return this.sendAsync(liveApiSockets.MediaSessionEventType.broadcast, {
      topic,
      data,
    });
  }

  public onBroadcast(topic: string, listener: (...args: any[]) => void): void {
    this.on(SocketIoClientWrapper.createBroadcastName(topic), listener);
  }

  public offBroadcast(topic: string, listener: (...args: any[]) => void): void {
    this.off(SocketIoClientWrapper.createBroadcastName(topic), listener);
  }

  public static createBroadcastName(topic: string): string {
    return `${liveApiSockets.MediaSessionEventType.broadcast}:${topic}`;
  }

  // THE FOLLOWING METHODS DO NO BELONG HERE!!
  // They should be in the component or at the very least the
  // session-context since the components should not be aware
  // of the underlying implementation

  private processJoinResponse(
    mediaSessionId: string,
    detail: liveApiSockets.MediaSessionEventResponseDetailsJoinPresentation,
  ): void {
    pipe(
      liveApiSockets.MediaSessionEventResponseDetailsJoin.decode(detail),
      fold(
        () => {
          this.setServerParams?.(null);
          console.warn("Unexpected response from server=", detail);
          this.socket?.disconnect();
          this.emit("error", new Error(detail as unknown as string));
          this.socket = undefined;
        },
        (data) => {
          this.setServerParams?.(data.serverParams);

          if (!this.socket) {
            return;
          }

          // now we have the config create the logger
          this.emit(liveApiSockets.MediaSessionEventType.sessionJoined, {
            mediaSessionId,
            profileReference: data.profile.profileReference,
            newRelicLogUrl: data.serverParams.newRelicLogUrl || undefined,
            environmentName: environment.name,
          });
        },
      ),
    );
  }

  public joinSession(
    sessionParams: SessionParams,
    capabilities: liveApiSockets.UserCapabilities,
  ) {
    if (!this.socket) {
      return;
    }
    this.socket.on("connect", () => {
      if (!this.socket) {
        return;
      }

      this.socket.emit(
        liveApiSockets.MediaSessionEventType.joinSession,
        { ...sessionParams, capabilities },
        (response: liveApiSockets.MediaSessionEventResponse): void => {
          this.processJoinResponse(
            sessionParams.mediaSessionId,
            response.detail as liveApiSockets.MediaSessionEventResponseDetailsJoinPresentation,
          );
        },
      );
    });
  }

  public sendChatMessage(message: string) {
    if (!this.socket) {
      return;
    }
    this.sendAsync(liveApiSockets.MediaSessionEventType.chat, { message }).then(
      (details: unknown) => {
        console.log(details);
      },
    );
  }

  public refreshCredentials(token: string) {
    this.token = token;

    if (!this.socket) {
      return;
    }

    this.socket.emit(liveApiSockets.CredentialsRefreshed, this.token);
  }
}

export default SocketIoClientWrapper;
