import { JanusJS } from "@proximie/janus-gateway";
import DeviceIncoming from "../Devices/DeviceIncoming";
import EndpointJanusVideo from "../Endpoints/Janus/EndpointJanusVideo";
import ConnectionMetadata from "../../models/ConnectionMetadata";
import { encodeStreamId } from "../../MediaUtil";
import FeedType from "../../models/FeedType";
import Monitor, { MonitorOptions } from "./Monitor";

interface JanusMonitorParticipant {
  id: number;
  display: string;
  publisher: boolean;
}

export interface MonitorConnectionJanusVideoOptions extends MonitorOptions {
  hostDeviceId: string;
}

export default class MonitorConnectionJanusVideo extends Monitor {
  private devices: Record<string, DeviceIncoming> = {};
  protected handle: JanusJS.PluginHandle | null = null;
  private intervalId: ReturnType<typeof setInterval> | null = null;

  constructor(
    protected room: EndpointJanusVideo,
    protected options: MonitorConnectionJanusVideoOptions,
  ) {
    super(room);
  }

  async open(): Promise<void> {
    console.log("MonitorConnectionJanusVideo: opening");

    const streamId = encodeStreamId({
      type: FeedType.Monitor,
      profileId: this.room.profileId,
    });

    const attachOptions: JanusJS.PluginOptions = {
      plugin: "OVERWRITE_ME",
      oncleanup: (): void => {
        console.debug("oncleanup");
        if (this.handle) {
          // in case we missed the initial event - close the connection
          this.close();
        }
      },
      detached: (): void => {
        console.debug("detached");
        if (this.handle) {
          // in case we missed the initial event - close the connection
          this.close();
        }
      },
      error: (error): void => {
        console.warn("Error from connection=", error);
        this.close(new Error(error));
      },
      onmessage: this.onMessage.bind(this),
    };

    if (!(this.room as EndpointJanusVideo).attach) {
      throw new Error("The endpoint is not Janus");
    }

    const room = this.room as EndpointJanusVideo;

    //LATER - can we have a ConnectionJanusVideoMonitor type?
    this.handle = await room.attach(attachOptions);

    await room.create(this.handle);

    await new Promise<void>((resolve, reject) => {
      const register = {
        request: "join",
        room: room.mediaSessionId,
        ptype: "publisher",
        id: streamId,
        display: JSON.stringify({}),
      };

      this.handle?.send({
        message: register,
        success: resolve,
        error: (error: string) => reject(new Error(error)),
      } as unknown as JanusJS.PluginMessage);
    });

    // if parseParticipantsList exists then we have the concept of a participant
    // list in the connection so we should ensure that we synchronise:
    // 1) When the connection to Janus is re-established
    // 2) Every x seconds (just to be sure)
    this.room.on("reconnected", () => {
      console.warn("Room re-connected - synchronise participant list");
      // empty list of devices - they may have changed since we disconnected
      this.devices = {};
      this.requestParticipants();
    });

    this.intervalId = setInterval(() => {
      // poll the participants every 30s in case we missed any
      this.requestParticipants();
    }, 30000);
  }

  private onMessage(message: JanusJS.Message): void {
    console.debug(`VideoMon:onMessage - message=${JSON.stringify(message)}`);
    // @ts-ignore Janus definitions are not complete
    const event = message["videoroom"];
    if (message["error"]) {
      // @ts-ignore Janus definitions are not complete
      console.warn("VideoMon:onMessage: error=", message["error"]);
    } else if (event === "joined") {
      // @ts-ignore Janus definitions are not complete
      if (message["publishers"]) {
        // @ts-ignore Janus definitions are not complete
        message["publishers"].forEach(this.addDevice.bind(this));
        // @ts-ignore Janus definitions are not complete
        const publishers = message["publishers"].map(
          (publisher: any): string => publisher.id,
        );
        window.dispatchEvent(
          new CustomEvent("video_monitor_joined", {
            detail: { publishers },
          }),
        );
      }
    } else if (event === "destroyed") {
      console.warn("VideoMon:onMessage: connection destroyed");
      this.close();
    } else if (event === "event") {
      // @ts-ignore Janus definitions are not complete
      if (message["publishers"]) {
        // @ts-ignore Janus definitions are not complete
        message["publishers"].forEach(this.addDevice.bind(this));
        // @ts-ignore Janus definitions are not complete
      } else if (message["leaving"]) {
        // @ts-ignore Janus definitions are not complete
        console.debug("VideoMon:onMessage: leaving=", message["leaving"]);
        // @ts-ignore Janus definitions are not complete
        this.removeDevice.bind(this)(message["leaving"]);
        // @ts-ignore Janus definitions are not complete
      } else if (message["unpublished"]) {
        console.debug(
          "VideoMon:onMessage: unpublished=",
          // @ts-ignore Janus definitions are not complete
          message["unpublished"],
        );
        // @ts-ignore Janus definitions are not complete
        this.removeDevice.bind(this)(message["unpublished"]);
        // @ts-ignore Janus definitions are not complete
      } else if (message["kicked"]) {
        console.debug(
          // @ts-ignore Janus definitions are not complete
          { streamId: message["kicked"] },
          "VideoMon:onMessage: kicked",
        );
        // @ts-ignore Janus definitions are not complete
        this.removeDevice.bind(this)(message["kicked"]);
      }
    } else {
      console.warn("VideoMon:onMessage: Unknown message=", message);
    }
  }

  private addDevice(participant: JanusMonitorParticipant): void {
    if (participant.publisher === false) {
      return;
    }
    const streamId = String(participant.id);
    if (this.devices[streamId]) {
      return;
    }

    console.debug({ streamId }, "VideoMon:addDevice: streamId=", streamId);

    let params: ConnectionMetadata;
    try {
      params = JSON.parse(participant.display);
    } catch (error) {
      console.warn({ streamId }, "Invalid metadata=", participant.display);
      params = {};
    }

    const deviceId = params.devices?.[0].deviceId || streamId;
    console.debug(`VideoMon:addDevice: Created deviceId=${deviceId}`);

    this.devices[streamId] = new DeviceIncoming(deviceId, {
      params,
      streamId,
      hostDeviceId: this.options.hostDeviceId,
    });

    this.devices[streamId].on("closed", () => {
      console.debug({ streamId }, "VideoMon: closed");
      delete this.devices[streamId];
    });

    this.devices[streamId].on("error", (error) => {
      console.debug({ streamId }, "VideoMon: error=", error);
      delete this.devices[streamId];
    });

    this.emit("added", this.devices[streamId]);
  }

  private removeDevice(streamId: string): void {
    console.debug({ streamId }, "VideoMon:removeDevice: streamId=", streamId);
    if (this.devices[streamId]) {
      this.emit("removed", this.devices[streamId]);
      this.devices[streamId].emit("closed");
      delete this.devices[streamId];
    }
  }

  private requestParticipants(): void {
    const request = {
      message: {
        request: "listparticipants",
        room: this.room.mediaSessionId,
      },
      success: (result: { participants: [] }) => {
        console.log("result=", result);
        result.participants.forEach(this.addDevice.bind(this));
      },
      error: (error: unknown) => {
        console.warn("Error getting participants - error=", error);
      },
    };
    this.handle && this.handle.send(request);
  }

  override async close(error?: Error): Promise<void> {
    console.debug("ConnectionJanus:close - handle=", !!this.handle);
    return new Promise<void>((resolve, reject) => {
      if (!this.handle) {
        // we've already closed the connection
        return resolve();
      }

      // ensure that close() cannot be called multiple times by clearing handle
      const tmpHandle = this.handle;
      this.handle = null;

      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      tmpHandle.hangup();

      tmpHandle.detach({
        success: () => {
          this.handle = null;
          resolve();
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (myError: any) => {
          // if we got an error while detaching then that is likely to imply that something
          // really bad happened - so pass on the error
          console.debug("ConnectionJanus: close detach - error=", myError);
          reject(new Error(myError));
        },
      });
    })
      .catch((myError: Error) => {
        console.warn("Failed to close connection - error=", myError);
        error = error || myError;
      })
      .finally(() => {
        //TODO - confirm other modules emit error
        if (error) {
          this.emit("error", error);
        } else {
          this.emit("closed");
        }
      });
  }

  override get deviceList(): DeviceIncoming[] {
    return Object.values(this.devices);
  }
}
