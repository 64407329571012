import Device, { DeviceType, DeviceOptions } from "./Device";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import ConnectionJanusVideoIncoming from "../Connections/Janus/ConnectionJanusVideoIncoming";
import ConnectionDcpVideoIncoming from "../Connections/Dcp/ConnectionDcpVideoIncoming";
import QualityVideoIncomingScreenshare from "../Quality/QualityVideoIncomingScreenshare";
import QualityVideoIncomingCamera from "../Quality/QualityVideoIncomingCamera";
import QualityVideoIncomingDcp from "../Quality/QualityVideoIncomingDcp";
import FeedType from "../../models/FeedType";
import Endpoint from "../Endpoints/Endpoint";
import { decodeStreamId } from "../../MediaUtil";
import ConnectionMetadata from "../../models/ConnectionMetadata";

export interface DeviceIncomingOptions extends DeviceOptions {
  streamId: string;
  params: ConnectionMetadata;
  hostDeviceId: string;
}

export default class DeviceIncoming extends Device {
  public override readonly deviceType = DeviceType.Incoming;
  // will be set in constructor
  public override mediaType = FeedType.None;

  constructor(deviceId: string, public options: DeviceIncomingOptions) {
    super(deviceId, options);

    const { type } = decodeStreamId(options.streamId);
    this.mediaType = type;
  }

  public override invoke(
    endpoint: Endpoint,
    _streamId: string,
    options: ConnectionOptions,
  ): Connection | null {
    console.debug({ streamId: this.options.streamId }, "Invoking");

    const myOptions: ConnectionOptions = {
      ...options,
      params: {
        capabilities: {},
        ...options.params,
        ...this.options.params,
      },
    };

    const qualityOptions = {
      bitrate: endpoint.config.bitrate,
    };

    let connection: ConnectionJanusVideoIncoming;
    if (this.options.params.deviceType === DeviceType.Dcp) {
      myOptions.quality = new QualityVideoIncomingDcp(qualityOptions);

      connection = new ConnectionDcpVideoIncoming(
        endpoint,
        this,
        this.options.streamId,
        myOptions,
      );
    } else {
      myOptions.quality =
        this.mediaType === FeedType.Screen
          ? new QualityVideoIncomingScreenshare(qualityOptions)
          : new QualityVideoIncomingCamera(qualityOptions);

      connection = new ConnectionJanusVideoIncoming(
        endpoint,
        this,
        this.options.streamId,
        myOptions,
      );
    }

    // for incoming connections the device only lives as long as the connection so propagate
    // the closed/error events down to the device
    connection.on("closed", () => {
      console.debug(
        { streamId: this.options.streamId },
        "DeviceIncoming: closed",
      );
      this.emit("closed");
    });

    connection.on("error", (error: Error) => {
      console.debug(
        { streamId: this.options.streamId },
        "DeviceIncoming: error=",
        error,
      );
      this.emit("closed");
    });

    return connection;
  }
}
