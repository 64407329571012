import React from "react";
import { useTroubleShooting } from "../TroubleShootingContext";
import { Box, Button, IconButton, Modal, useTheme } from "@mui/material";
import { TroubleShootingReportStyled } from "./TroubleShootingReport.style";
import { Close, InfoOutlined } from "@mui/icons-material";
import { TroubleShootingReportITSuggestionContainer } from "./TroubleShootingReportITSuggestion";
import { TroubleShootingReportPanel } from "./TroubleShootingReportPanel";
import {
  TroubleShootingReportPanelGrid,
  TroubleShootingReportPanelGridRow,
} from "./TroubleShootingReportPanelGrid";
import {
  ServiceReachabilityValue,
  StreamingProtocolValue,
} from "../TroubleShootingTypes";
import { environment } from "../../../../environments/environment";
import {
  BandwidthIssueDescription,
  LatencyIssueDescription,
  PacketLossIssueDescription,
  ServiceReachabilityIssueDescription,
  StreamingProtocolIssueDescription,
  VideoIssueDescription,
} from "../shared/TroubleShootingDescriptions";
import {
  DifferentNetworkRecommendation,
  DistanceToRouterRecommendation,
  FirewallITRecommendation,
  pushRecommendationIfNotExist,
  Recommendation,
  ReduceFeedsRecommendation,
  VideoQualityRecommendation,
  WiredConnectionRecommendation,
} from "../shared/TroubleShootingRecommendations";
import {
  Subtitle2Typography,
  Subtitle3Typography,
} from "../shared/CustomTypography";

function getBlockedPortsAndAddresses(
  streamingProtocol: StreamingProtocolValue,
  serviceReachability: ServiceReachabilityValue,
): string[] {
  const blockedPortsAndAddresses: string[] = [];

  if (streamingProtocol.criticality !== "Good") {
    blockedPortsAndAddresses.push(`*.${environment.dnsRecordName}:10000/UDP`);
  }

  if (serviceReachability.criticality !== "Good") {
    for (const { success, domain } of Object.values(
      serviceReachability.value,
    )) {
      if (!success) {
        blockedPortsAndAddresses.push(`${domain}:443/TCP`);
      }
    }
  }

  return blockedPortsAndAddresses;
}

export const TroubleShootingReport = () => {
  const { colors } = useTheme();
  const {
    detailedReportOpen,
    setDetailedReportOpen,
    criticalityReport,
    openSupportTicket,
    lastCriticalState: { criticalityReport: lastCriticalityReport, timestamp },
  } = useTroubleShooting();

  const {
    network: { outgoingBandwidth, incomingBandwidth, latency, packetLoss },
    firewall: { streamingProtocol, serviceReachability },
    video: { videoQuality },
  } = lastCriticalityReport ?? criticalityReport;

  const issueDescriptions: string[] = [];
  const recommendations: Recommendation[] = [];
  if (outgoingBandwidth.criticality !== "Good") {
    issueDescriptions.push(BandwidthIssueDescription);
    pushRecommendationIfNotExist(
      recommendations,
      ReduceFeedsRecommendation,
      WiredConnectionRecommendation,
      DifferentNetworkRecommendation,
      DistanceToRouterRecommendation,
      VideoQualityRecommendation,
    );
  }
  if (latency.criticality !== "Normal") {
    issueDescriptions.push(LatencyIssueDescription);
    pushRecommendationIfNotExist(
      recommendations,
      ReduceFeedsRecommendation,
      WiredConnectionRecommendation,
      DifferentNetworkRecommendation,
      DistanceToRouterRecommendation,
      VideoQualityRecommendation,
    );
  }
  if (packetLoss.criticality !== "Normal") {
    issueDescriptions.push(PacketLossIssueDescription);
    pushRecommendationIfNotExist(
      recommendations,
      ReduceFeedsRecommendation,
      WiredConnectionRecommendation,
      DifferentNetworkRecommendation,
      DistanceToRouterRecommendation,
      VideoQualityRecommendation,
    );
  }
  if (streamingProtocol.criticality !== "Good") {
    issueDescriptions.push(
      StreamingProtocolIssueDescription(streamingProtocol.value),
    );
    pushRecommendationIfNotExist(
      recommendations,
      FirewallITRecommendation(
        getBlockedPortsAndAddresses(streamingProtocol, serviceReachability),
      ),
    );
  }
  if (serviceReachability.criticality !== "Good") {
    issueDescriptions.push(ServiceReachabilityIssueDescription);
    pushRecommendationIfNotExist(
      recommendations,
      FirewallITRecommendation(
        getBlockedPortsAndAddresses(streamingProtocol, serviceReachability),
      ),
    );
  }
  if (videoQuality.criticality !== "Good") {
    issueDescriptions.push(VideoIssueDescription);
    pushRecommendationIfNotExist(
      recommendations,
      ReduceFeedsRecommendation,
      WiredConnectionRecommendation,
      DifferentNetworkRecommendation,
      DistanceToRouterRecommendation,
      VideoQualityRecommendation,
    );
  }

  return (
    <Modal
      open={detailedReportOpen}
      onClose={() => setDetailedReportOpen(false)}
      sx={{
        zIndex: 10,
        "& .MuiBackdrop-root": {
          transitionDuration: "0s !important",
        },
        overflowY: "auto",
      }}
    >
      <TroubleShootingReportStyled>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
          margin={"0"}
          padding={"0"}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width={"100%"}
            margin={"0"}
            padding={"0"}
            gap={"24px"}
          >
            <Subtitle2Typography
              fontSize={"20px"}
              lineHeight={"160%"}
              sx={{
                fontFeatureSettings: "'liga' off, 'cling' off",
              }}
            >
              Troubleshooting report
            </Subtitle2Typography>
            {lastCriticalityReport && (
              <Subtitle3Typography
                lineHeight={"20px"}
                letterSpacing={"0.035px"}
                color={colors.BlackA500}
              >
                Last updated at:{" "}
                {timestamp.toLocaleTimeString("en-gb", {
                  hour12: false,
                  timeZoneName: "short",
                })}
              </Subtitle3Typography>
            )}
          </Box>
          <IconButton onClick={() => setDetailedReportOpen(false)}>
            <Close
              style={{
                width: "20px",
                height: "20px",
                color: colors.BlackMediumEmphasis,
              }}
            />
          </IconButton>
        </Box>
        <TroubleShootingReportITSuggestionContainer />

        <Box
          display={"flex"}
          flexDirection="row"
          width={"100%"}
          justifyContent={"space-evenly"}
          gap={"8px"}
          overflow={"hidden"}
          sx={{
            fontFamily: "SuisseIntl",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "0.048px",
            color: colors.Grey600,
          }}
        >
          <Box display={"flex"} flexDirection="column" flex={0.7} gap={"8px"}>
            <TroubleShootingReportPanel
              title="Network"
              padding={"12px 12px 8px 12px"}
            >
              <TroubleShootingReportPanelGrid>
                <TroubleShootingReportPanelGridRow
                  title={"Upload bandwidth"}
                  description={"The upload speed of your connection"}
                  criticality={outgoingBandwidth.criticality}
                  value={
                    outgoingBandwidth.criticality === "N/A"
                      ? "-"
                      : `${(outgoingBandwidth.value / 1000000).toFixed(1)}Mbps`
                  }
                />
                <TroubleShootingReportPanelGridRow
                  title={"Download bandwidth"}
                  description={"The download speed of your connection"}
                  criticality={incomingBandwidth.criticality}
                  value={
                    incomingBandwidth.criticality === "N/A"
                      ? "-"
                      : `${(incomingBandwidth.value / 1000000).toFixed(1)}Mbps`
                  }
                />
                <TroubleShootingReportPanelGridRow
                  title={"Latency"}
                  description={"The delay in video/audio transmission"}
                  criticality={latency.criticality}
                  value={`${latency.value.toFixed(0)}ms`}
                />
                <TroubleShootingReportPanelGridRow
                  title={"Packet Loss"}
                  description={"Data loss during audio/video transmission"}
                  criticality={packetLoss.criticality}
                  value={`${packetLoss.value.toFixed(2)}%`}
                />
              </TroubleShootingReportPanelGrid>
            </TroubleShootingReportPanel>
            <TroubleShootingReportPanel
              title="Firewall"
              padding={"12px 12px 8px 12px"}
            >
              <TroubleShootingReportPanelGrid>
                <TroubleShootingReportPanelGridRow
                  title={"Streaming protocol"}
                  description={"The protocol used for our streaming services"}
                  criticality={streamingProtocol.criticality}
                  value={streamingProtocol.value}
                />
                <TroubleShootingReportPanelGridRow
                  title={"Service reachability"}
                  description={"The ability to access the streaming service"}
                  criticality={serviceReachability.criticality}
                />
              </TroubleShootingReportPanelGrid>
            </TroubleShootingReportPanel>
            <TroubleShootingReportPanel
              title="Video"
              padding={"12px 12px 8px 12px"}
            >
              <TroubleShootingReportPanelGrid>
                <TroubleShootingReportPanelGridRow
                  title={"Video Quality"}
                  description={"The clarity and smoothness of video"}
                  criticality={videoQuality.criticality}
                  value={videoQuality.value}
                />
              </TroubleShootingReportPanelGrid>
            </TroubleShootingReportPanel>
          </Box>
          <Box display={"flex"} flexDirection="column" flex={1} gap={"8px"}>
            <TroubleShootingReportPanel title="Summary of issues" flex={"0"}>
              <Subtitle3Typography
                fontSize={"16px"}
                lineHeight={"24px"}
                letterSpacing={"0.08px"}
                color={colors.Grey900}
              >
                <ul
                  style={{
                    padding: "16px 24px 0 24px",
                    margin: "0",
                    textIndent: "-4px",
                  }}
                >
                  {issueDescriptions.length <= 0 ? (
                    <li
                      style={{
                        listStyleType: "disc",
                        display: "list-item",
                      }}
                    >
                      No issues detected
                    </li>
                  ) : (
                    issueDescriptions.map((issue) => (
                      <li
                        key={issue}
                        style={{
                          listStyleType: "disc",
                          display: "list-item",
                        }}
                      >
                        {issue}
                      </li>
                    ))
                  )}
                </ul>
              </Subtitle3Typography>
            </TroubleShootingReportPanel>
            <TroubleShootingReportPanel
              title="Recommended actions"
              flex={issueDescriptions.length <= 0 ? "0" : "1"}
              overflowY={"auto"}
              minHeight={"108px"}
            >
              <ol
                style={{
                  fontFamily: "SuisseIntl",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.08px",
                  color: colors.Grey900,
                  padding: "16px 24px 0 24px",
                  margin: "0",
                }}
              >
                {recommendations.length <= 0 ? (
                  <li
                    style={{
                      listStyleType: "disc",
                      display: "list-item",
                    }}
                  >
                    No issues detected
                  </li>
                ) : (
                  recommendations
                    .sort((a, b) => a.index - b.index)
                    .map((recommendation) => (
                      <li
                        key={recommendation.value}
                        style={{
                          listStyle: "decimal",
                          display: "list-item",
                        }}
                      >
                        {recommendation.value}
                        {recommendation.extras &&
                          recommendation.extras.length > 0 && (
                            <ul
                              style={{
                                fontFamily: "SuisseIntl",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.08px",
                                color: colors.Grey900,
                                padding: "8px 24px 8px 24px",
                                margin: "0",
                                textIndent: "-4px",
                              }}
                            >
                              {recommendation.extras.map((extra) => (
                                <li
                                  key={extra}
                                  style={{
                                    listStyleType: "disc",
                                    display: "list-item",
                                  }}
                                >
                                  {extra}
                                </li>
                              ))}
                            </ul>
                          )}
                      </li>
                    ))
                )}
              </ol>
            </TroubleShootingReportPanel>
            {issueDescriptions.length <= 0 && (
              <TroubleShootingReportPanel title="">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"12px"}
                  alignSelf={"stretch"}
                  height={"100%"}
                  width={"100%"}
                >
                  <InfoOutlined
                    style={{
                      width: "20px",
                      height: "20px",
                      color: colors.BlackMediumEmphasis,
                    }}
                  />
                  <Subtitle3Typography
                    sx={{
                      fontWeight: "700",
                      lineHeight: "20px",
                      letterSpacing: "0.035px",
                      color: colors.BlackMediumEmphasis,
                    }}
                  >
                    Still experiencing issues?
                  </Subtitle3Typography>

                  <Subtitle3Typography
                    sx={{
                      lineHeight: "20px",
                      letterSpacing: "0.035px",
                      color: colors.BlackMediumEmphasis,
                      textAlign: "center",
                      width: "362px",
                    }}
                  >
                    We recommend contacting your IT department to help
                    troubleshoot. If you&apos;re still having trouble, contact
                    support.
                  </Subtitle3Typography>

                  <Button onClick={openSupportTicket}>Contact support</Button>
                </Box>
              </TroubleShootingReportPanel>
            )}
          </Box>
        </Box>
      </TroubleShootingReportStyled>
    </Modal>
  );
};
