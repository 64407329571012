import React from "react";
import { useFlavor } from "libs/flavorinator/src/lib/useFlavor";
import { Box } from "@mui/material";

interface LogoProps {
  variant: "dashboard-app" | "media-app";
  alt?: string;
  wrapperStyle?: React.CSSProperties;
  logoStyle?: React.CSSProperties;
  ["data-testid"]?: string;
  ["data-cy"]?: string;
}

export function Logo(props: LogoProps): JSX.Element {
  const { logos } = useFlavor();
  const logoSrc =
    props.variant === "dashboard-app" ? logos.dashboardApp : logos.mediaApp;
  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    padding: "8px 10px",
    ...props.wrapperStyle,
  };
  const logoStyle = {
    width: "auto",
    height: "43px",
    ...props.logoStyle,
  };

  return (
    <Box style={wrapperStyle}>
      <img
        src={logoSrc}
        style={logoStyle}
        data-testid={props["data-testid"]}
        data-cy={props["data-cy"]}
        alt={props.alt ?? "Logo"}
      />
    </Box>
  );
}
