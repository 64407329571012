import { appointments, countries } from "@proximie/api";
import * as t from "io-ts";
import { nullable } from "io-ts/lib/Type";
import { makeNoContentRoute, makeRoute } from "./dataregion-api";

export const empty = t.type({});

const LaunchMediaSessionRequest = t.type({
  appointmentId: t.number,
  appointmentUuid: t.string,
  mediaSessionId: nullable(t.string),
  signalingPrimitives: t.union([t.undefined, t.boolean]),
});

const MediaSessionStatusResponse = t.type({
  appointmentId: t.number,
  mediaSessionStatus: nullable(t.string),
});

export const UpdateMediaSessionRequest = t.strict({
  mediaSessionId: t.string,
  status: nullable(t.keyof(appointments.MediaSessionStatus)),
});

export const DeleteMediaSessionRequest = t.type({
  mediaSessionId: t.string,
});

export const DeprovisionMediaSessionRequest = t.type({
  mediaSessionId: t.string,
  profileId: t.number,
  userId: t.string,
  organisationUuid: t.string,
  country: countries.Country,
  appointmentId: t.union([t.undefined, t.string]),
});

export const ParticipantAddedEventRequest = t.type({
  profileId: t.number,
  userId: t.string,
  mediaSessionId: t.string,
  participantId: t.string,
});

export interface MediaSessionPatch {
  status?: appointments.MediaSessionStatus;
  startedAt?: Date;
}

//FIXME - maybe change to PUT request with noContentRoute as we do not return anything
export const launch = makeRoute("/launch", LaunchMediaSessionRequest, empty);

export const update = makeNoContentRoute(
  "/media-sessions/:mediaSessionId",
  UpdateMediaSessionRequest,
);

export const deleteMediaSession = makeNoContentRoute(
  "/media-sessions/:mediaSessionId",
  DeleteMediaSessionRequest,
);

export const deprovisionMediaSession = makeNoContentRoute(
  "/media-sessions/:mediaSessionId/deprovision",
  DeprovisionMediaSessionRequest,
);

export const participantAddedEvent = makeNoContentRoute(
  "/media-sessions/:mediaSessionId/events/participant_added",
  ParticipantAddedEventRequest,
);

const FetchMediaSessionStatusesFilters: [t.Mixed, t.Mixed, ...Array<t.Mixed>] =
  [
    t.literal("SCHEDULED"),
    t.literal("ACTIVE"),
    t.literal("CLOSING"),
    t.literal("CLOSED"),
  ];
const FetchMediaSessionStatusesRequest = t.type({
  status: t.union([
    t.undefined,
    t.array(t.union([...FetchMediaSessionStatusesFilters])),
    ...FetchMediaSessionStatusesFilters,
  ]),
  apptIds: t.array(t.number),
});

export const fetchSessionStatuses = makeRoute(
  "/media-session-statuses",
  FetchMediaSessionStatusesRequest,
  t.array(MediaSessionStatusResponse),
);

export type LaunchMediaSessionRequest = t.TypeOf<
  typeof LaunchMediaSessionRequest
>;
export type MediaSessionStatusResponse = t.TypeOf<
  typeof MediaSessionStatusResponse
>;
export type FetchMediaSessionStatusesRequest = t.TypeOf<
  typeof FetchMediaSessionStatusesRequest
>;

export type DeleteMediaSessionRequest = t.TypeOf<
  typeof DeleteMediaSessionRequest
>;

export type DeprovisionMediaSessionRequest = t.TypeOf<
  typeof DeleteMediaSessionRequest
>;
