import { Business } from "@mui/icons-material";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { OrganisationMenuToggleStyled } from "./MenuWithIcons.style";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface MenuItemData {
  id: string;
  name: string;
}

interface MenuWithIconsProps {
  currentMenuItemData: MenuItemData;
  allMenuItemsData: Array<MenuItemData>;
  switchCurrentOrgByUuid: (id: string) => void;
}

export const MenuWithIcons = (props: MenuWithIconsProps) => {
  const { colors } = useTheme();
  const { currentMenuItemData, allMenuItemsData, switchCurrentOrgByUuid } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    closeMenu();
  };

  const handleClickMenuItem = (menuItemId: string) => {
    closeMenu();
    switchCurrentOrgByUuid(menuItemId);
  };

  return (
    <Box mr={1.5}>
      <Tooltip title={currentMenuItemData.name}>
        <OrganisationMenuToggleStyled
          variant={"outlined"}
          aria-controls="simple-menu"
          data-cy="selected-organisation"
          aria-haspopup="true"
          onClick={handleClickAnchor}
          startIcon={<Business color={"inherit"} />}
          endIcon={<ArrowDropDownIcon />}
        >
          <Typography
            component={"span"}
            data-testid="menu-with-icons-anchor-text"
            data-cy="menu-with-icons-anchor-text"
            className="button-copy"
          >
            {currentMenuItemData.name}
          </Typography>
        </OrganisationMenuToggleStyled>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        data-cy="menu-with-icons"
      >
        {allMenuItemsData.map((organisation) => {
          const cyAttribute = `menu-with-icons-item-${organisation.name
            .replace(/\s+/g, "")
            .toLowerCase()}`;
          return (
            <MenuItem
              selected={organisation.name === currentMenuItemData.name}
              key={organisation.id}
              className="menu-item"
              title={organisation.name}
              onClick={() => {
                handleClickMenuItem(organisation.id);
              }}
              data-cy={cyAttribute}
              data-testid={`switch-org-item-${organisation.id}`}
            >
              <ListItemIcon>
                <Business htmlColor={colors.Grey800} />
              </ListItemIcon>
              <ListItemText>{organisation.name}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default MenuWithIcons;
