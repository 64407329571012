import styled from "@emotion/styled";

const VideoStatsStyled = styled.div`
  position: relative;
  margin: 1em;
  width: 150px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export default VideoStatsStyled;
