import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckIcon from "@mui/icons-material/Check";

import { VideoInfo } from "../../utils/VideoStore";
import { MediaUtil, VideoMaskHandler } from "@proximie/media";
import { HelpOutline } from "@mui/icons-material";

import { SnackbarContext } from "@proximie/components";
import { useTranslation } from "react-i18next";
import { useCameraLabel } from "../../contexts/camera-label-context";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 242,
  },
});

export interface ExtendedVideoInfo extends VideoInfo {
  isSelected?: boolean;
  masker?: VideoMaskHandler;
  isMissing?: boolean;
  isNew?: boolean;
}

export interface VideoLastStatePanelProps {
  videos: ExtendedVideoInfo[];
  handleCloseLastStatePanel: () => void;
  handleAddLastStatePanelVideos: () => void;
  removeLastStateVideo: (streamId: string) => void;
  editPrivacyLastStateVideo: (streamId: string) => void;
  undoRemoveLastStateVideo: (streamId: string) => void;
}

export const VideoLastStatePanel = ({
  videos,
  handleCloseLastStatePanel,
  handleAddLastStatePanelVideos,
  removeLastStateVideo,
  editPrivacyLastStateVideo,
  undoRemoveLastStateVideo,
}: VideoLastStatePanelProps) => {
  const { colors, palette } = useTheme();
  const feedBeingAdded = videos.filter(
    (v) => !v.isMissing && v.isSelected,
  ).length;
  const matches = useMediaQuery("(max-height:800px)");

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          maxWidth: 800,
          maxHeight: `calc(100% - ${matches ? "80px" : "200px"});`,
        },
      }}
      componentsProps={{
        backdrop: { style: { backgroundColor: "black", opacity: "15%" } },
      }}
      data-testid="video-last-state-panel"
    >
      <DialogTitle sx={{ padding: 0, width: "100%" }}>
        <Box padding={0} margin={0} display="flex">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={8}
            paddingTop="0"
            paddingLeft="0"
          >
            <span>
              Select video devices
              <Typography
                data-testid="last-state-feeds-count"
                sx={{
                  marginLeft: "13px",
                  display: "inline",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "21px",
                  letterSpacing: "0.005em",
                  color: colors.BlackMediumEmphasis,
                }}
              >
                {videos.filter((v) => v.isSelected).length} selected{" "}
                {videos.filter((v) => v.isSelected).length > 3
                  ? "(maximum 4 allowed)"
                  : ""}
              </Typography>
            </span>
            <Box
              width="min-content"
              height="min-content"
              display="inline-flex"
              alignItems="center"
            >
              <Typography
                sx={{
                  mx: "5px",
                  fontSize: "14px",
                  opacity: "0.6",
                }}
                noWrap
              >
                Privacy control
              </Typography>
              <CustomWidthTooltip
                title={
                  <span style={{ fontSize: "11px" }}>
                    Use privacy control to blur out sensitive information such
                    as names, faces or tattoos.
                  </span>
                }
                placement="top"
                arrow
              >
                <IconButton
                  sx={{
                    margin: "0 !important",
                    color: colors.Grey700,
                    width: "28px",
                    height: "28px",
                    "&:hover": {
                      backgroundColor: colors.Brand50,
                    },
                  }}
                >
                  <HelpOutline />
                </IconButton>
              </CustomWidthTooltip>
            </Box>
          </Box>
          <Box
            flex={1}
            margin="0"
            padding="0"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseLastStatePanel}
              sx={{
                fontSize: "14px",
                color: palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            marginTop: "8px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "24px 32px",
          }}
        >
          {videos
            .sort((a, b) => {
              const {
                index: indexA,
                profileId: userIdA,
                timestamp: timestampA,
              } = MediaUtil.decodeStreamId(a.streamId);
              const {
                index: indexB,
                profileId: userIdB,
                timestamp: timestampB,
              } = MediaUtil.decodeStreamId(b.streamId);
              return (
                indexA - indexB || userIdA - userIdB || timestampA - timestampB
              );
            })
            .map((video) => (
              <VideoBox
                removeLastStateVideo={removeLastStateVideo}
                editPrivacyLastStateVideo={editPrivacyLastStateVideo}
                undoRemoveLastStateVideo={undoRemoveLastStateVideo}
                video={video}
                key={video.streamId}
                selectedCount={videos.filter((v) => v.isSelected).length}
              />
            ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px 24px 24px" }}>
        <Box
          width="100%"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
        >
          <Box sx={{ margin: "0!important", height: "48px" }}>
            <Button
              variant="contained"
              onClick={handleAddLastStatePanelVideos}
              sx={{
                backgroundColor: palette.secondary.main,
                padding: "12px 32px",
              }}
              disabled={feedBeingAdded === 0}
              data-testid="last-state-add"
            >
              Add to session
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseLastStatePanel}
              sx={{
                padding: "12px 32px",
                height: "48px",
              }}
              data-testid="last-state-cancel"
              data-cy="last-state-cancel"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export interface VideoBoxProps {
  video: ExtendedVideoInfo;
  selectedCount: number;
  removeLastStateVideo: (streamId: string) => void;
  editPrivacyLastStateVideo: (streamId: string) => void;
  undoRemoveLastStateVideo: (streamId: string) => void;
}

export const VideoBox = ({
  video,
  selectedCount,
  removeLastStateVideo,
  editPrivacyLastStateVideo,
  undoRemoveLastStateVideo,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
VideoBoxProps) => {
  const { colors, palette } = useTheme();
  const { t } = useTranslation();
  const { get: getCameraLabel } = useCameraLabel();

  const { showSnackbar } = SnackbarContext.useSnackbarContext();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current && video.masker && !video.isMissing) {
      videoRef.current.srcObject = video.masker.getStream();
    }
  }, [video.isMissing, video.masker]);

  const handleEditPrivacy = () => {
    if (video.isSelected) editPrivacyLastStateVideo(video.streamId);
  };

  const toggleSelection = () => {
    if (!video.isSelected) {
      if (selectedCount < 4) {
        undoRemoveLastStateVideo(video.streamId);
      } else {
        showSnackbar({
          message: {
            body: t("common.components.snackbar.messages.maxDevicesAllowed"),
          },
          autoHideDuration: 4000,
          severity: "error",
        });
      }
    } else removeLastStateVideo(video.streamId);
  };

  return (
    <Box
      data-testid="last-state-video-box"
      sx={{
        backgroundColor: colors.BlackLowEmphasis,
        boxShadow: !video.isMissing
          ? `0px 0px 4px ${colors.BlackLowEmphasis}, 0px 4px 8px ${colors.BlackLowEmphasis}`
          : "none",
        borderRadius: "4px",
        position: "relative",
        width: "349px",
        height: "196px",
        overflow: "hidden",
      }}
    >
      {video.isMissing && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5,
            padding: "18px",
            textAlign: "center",
          }}
        >
          {video.isMissing && (
            <ErrorOutlineIcon
              sx={{
                color: palette.text.secondary,
                marginBottom: "8px",
              }}
            />
          )}

          <Typography
            sx={{ fontSize: 12 }}
            color="text.secondary"
            data-testid="last-state-disconnect-label"
          >
            {video.isNew
              ? "Video device disconnected."
              : "Video device from previous session not connected."}
          </Typography>
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            {getCameraLabel(video.label)}
          </Typography>
          <Typography sx={{ fontSize: 12 }} color="text.secondary">
            Check the connection and power supply.
          </Typography>
        </Box>
      )}

      {!video.isMissing && !video.masker && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5,
            padding: "18px",
            textAlign: "center",
            backgroundColor: "rgba(234,235,236,0.6)", // "#EAEBEC",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      {!video.isSelected && !video.isMissing && (
        <Box
          onClick={toggleSelection}
          data-testid="last-state-disabled-shader"
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: colors.BlackDisabled,
            zIndex: selectedCount > 3 ? 4 : 2,
            "&:hover": {
              "& ~ div .MuiCheckbox-root": {
                scale: video.isSelected || selectedCount < 4 ? "1.1" : "1",
              },
            },
          }}
        ></Box>
      )}

      <Box
        sx={{
          opacity: !video.isMissing ? 1 : 0,
          height: "100%",
          width: "100%",
          "&:hover": {
            "& .MuiCheckbox-root": {
              scale: video.isSelected || selectedCount < 4 ? "1.1" : "1",
            },
          },
        }}
      >
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width="100%"
          padding={1}
          sx={{
            zIndex: 1,
            width: "fit-content",
          }}
        >
          <Typography
            data-testid="last-state-video-label"
            sx={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              letterSpacing: "0.0025em",
              lineHeight: "20px",
              color: "white",
              backgroundColor: colors.Grey900,
              padding: "2px 4px",
              width: "fit-content",
              borderRadius: "2px",
            }}
          >
            {getCameraLabel(video.label)}
          </Typography>

          <Box
            sx={{
              borderRadius: "2px",
              width: "fit-content",
              color: "white",
              backgroundColor:
                video.masker && video.masker.getMasks().length > 0
                  ? colors.Green700
                  : colors.Grey900,

              "&:hover": {
                backgroundColor:
                  video.masker && video.masker.getMasks().length > 0
                    ? colors.Green600
                    : colors.Grey600,
              },
            }}
            mt={1}
          >
            <Typography
              data-testid="last-state-privacy-label"
              sx={{
                display: "inline",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                letterSpacing: "0.0025em",
                lineHeight: "20px",
                padding: "2px 8px 4px",
                width: "fit-content",
              }}
            >
              {video.masker && video.masker.getMasks().length > 0
                ? "Privacy control applied"
                : "No privacy control"}
            </Typography>

            <Button
              variant="text"
              onClick={handleEditPrivacy}
              data-testid="last-state-edit-privacy"
              sx={{
                padding: "0 8px",
                height: "24px",
                width: "43px",
                cursor: "pointer",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "20px",
                minWidth: "43px",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {video.masker && video.masker.getMasks().length > 0
                ? "Edit"
                : "Add"}
            </Button>
          </Box>
        </Box>

        <Box
          position={"absolute"}
          top={0}
          right={0}
          padding={0}
          margin={1}
          sx={{
            zIndex: 3,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <Checkbox
            sx={{ margin: 0, padding: 0 }}
            checked={video.isSelected === true}
            data-testid="last-state-enable-checkbox"
            icon={
              <RadioButtonUncheckedOutlinedIcon
                fontSize="large"
                sx={{
                  color: colors.Grey600,
                  backgroundColor: colors.Grey600,
                  borderRadius: "50%",
                  border: `2px solid ${colors.White}`,
                  height: "32px",
                  width: "32px",
                }}
              />
            }
            checkedIcon={
              <CheckIcon
                fontSize="large"
                sx={{
                  color: "white",
                  backgroundColor: colors.BrandSecondary,
                  borderRadius: "50%",
                  border: `2px solid ${colors.White}`,
                  height: "32px",
                  width: "32px",
                }}
              />
            }
          />{" "}
        </Box>

        <video
          onClick={toggleSelection}
          data-testid="last-state-video"
          ref={videoRef}
          style={{
            cursor: "pointer",
            background: colors.Grey600,
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "100%",
            minHeight: 0,
            flex: 1,
          }}
          autoPlay
        ></video>
      </Box>
    </Box>
  );
};
