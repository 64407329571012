import { MetaData, Logger, LogLevel, LogProps, NewRelicConfig } from "./common";
import axios from "axios";

const MAX_MESSAGES_IN_BATCH = 1000;

const BATCH_PERIOD_MS = 1000;
const PURGE_PERIOD_MS = 60000;

interface NewRelicBrowserLog {
  timestamp: number;
  level: string;
  message: string;
  stackTrace?: string;
  error?: Record<string, unknown>;
  arguments?: string[];
  [key: string]: unknown;
}

class NewRelicLogger {
  private store: NewRelicBrowserLog[] = [];
  private batchTimerId: ReturnType<typeof setTimeout> | null = null;
  private purgeTimerId: ReturnType<typeof setTimeout> | null = null;

  constructor(private nrConfig: NewRelicConfig, private metadata: MetaData) {
    this.restartBatchTimer();
    this.restartPurgeTimer();
  }

  private restartBatchTimer(): void {
    if (this.batchTimerId) {
      clearTimeout(this.batchTimerId);
    }
    this.batchTimerId = setTimeout(() => {
      this.sendBatch();
    }, BATCH_PERIOD_MS);
  }

  private restartPurgeTimer(): void {
    if (this.purgeTimerId) {
      clearTimeout(this.purgeTimerId);
    }
    this.purgeTimerId = setTimeout(() => {
      console.warn("Purge timer expired - clearing logs");
      this.store = [];
    }, PURGE_PERIOD_MS);
  }

  private async sendBatch(): Promise<void> {
    if (this.store.length > 0) {
      const body = [
        {
          common: this.metadata,
          logs: this.store.splice(0, MAX_MESSAGES_IN_BATCH),
        },
      ];
      try {
        await axios.post(this.nrConfig.hostURL, body, {
          headers: {
            Authorization: `Bearer ${this.nrConfig.auth}`,
          },
        });
        this.restartPurgeTimer();
      } catch (error) {
        console.log("Error sending logs", error);
        // put the logs back at the front of the store
        this.store = [...body[0].logs, ...this.store];
      }
    }
    this.restartBatchTimer();
  }

  set config(config: Partial<NewRelicConfig>) {
    this.nrConfig = { ...this.nrConfig, ...config };
  }

  public log(level: LogLevel, props: LogProps): void {
    const timestamp = Date.now();

    const attributes = props.attributes;
    delete props.attributes;

    this.store.push({
      timestamp,
      level,
      ...props,
      ...attributes,
    });
  }
}

export interface NewRelicLoggerProps extends MetaData {
  config: NewRelicConfig;
  [key: string]: unknown;
}

export const createNewRelicBrowserLogger = ({
  config,
  ...loggerProps
}: NewRelicLoggerProps): Logger => {
  const loggerHandler = new NewRelicLogger(config, loggerProps);
  return {
    setConfig: (newConfig: Partial<NewRelicConfig>) => {
      loggerHandler.config = newConfig;
    },
    info: (props: LogProps) => loggerHandler.log(LogLevel.INFO, props),
    warn: (props: LogProps) => loggerHandler.log(LogLevel.WARN, props),
    error: (props: LogProps) => loggerHandler.log(LogLevel.ERROR, props),
  };
};
