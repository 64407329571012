import React, {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SessionJoinedProps } from "../common";
import {
  AppName,
  Logger,
  createLogger,
  getEnvironment,
} from "@proximie/common";
import overrideLogger from "../overrideLogger";
import { useAuthenticatedUser } from "./media-client-authenticated-user-context";
import { UseSocketIoContext } from "./socket-io-context";
import { liveApiSockets } from "@proximie/dataregion-api";

export interface LoggerContext {
  token: string | undefined;
}

interface LoggerProviderProps {
  children: ReactNode;
}

const LoggerContext = createContext<LoggerContext | null>(null);

export const LoggerProvider = ({ children }: LoggerProviderProps) => {
  const authContext = useAuthenticatedUser();
  const socketContext = UseSocketIoContext();

  const [logger, setLogger] = useState<Logger | undefined>(undefined);

  const [loggerProps, setLoggerProps] = useState<
    SessionJoinedProps | undefined
  >(undefined);

  const loggerProviderValue = useMemo(
    () => ({ token: loggerProps?.token }),
    [loggerProps?.token],
  );

  const generateLogger = ({
    newRelicLogUrl,
    profileReference,
    mediaSessionId,
    environmentName,
  }: SessionJoinedProps) => {
    const token = authContext?.token || "NO_TOKEN";

    setLoggerProps({
      newRelicLogUrl,
      profileReference,
      mediaSessionId,
      environmentName,
      token,
    });

    const logger = createLogger({
      configs: {
        newRelic: {
          auth: token,
          hostURL: newRelicLogUrl || "",
        },
      },
      mediaSessionId,
      profileReference,
      app: AppName.LIVE,
      env: getEnvironment(environmentName),
      version: "1.0",
    });

    setLogger(logger);

    if (newRelicLogUrl) {
      overrideLogger(logger);
    }
  };

  useEffect(() => {
    if (authContext?.token && loggerProps) {
      setLoggerProps({
        ...loggerProps,
        token: authContext?.token,
      });

      logger?.setConfig?.({ auth: authContext?.token });
    }
  }, [authContext?.token]);

  useEffect(() => {
    socketContext.on(
      liveApiSockets.MediaSessionEventType.sessionJoined,
      generateLogger,
    );

    return () => {
      socketContext.off(
        liveApiSockets.MediaSessionEventType.sessionJoined,
        generateLogger,
      );
    };
  }, []);

  return (
    <LoggerContext.Provider value={loggerProviderValue}>
      {children}
    </LoggerContext.Provider>
  );
};
