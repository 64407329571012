import React, { FC, useEffect, useState } from "react";
import VideoStatsStyled from "./VideoStats.style";
import { GuiStats, IceType, Quality, WebRTCUtil } from "@proximie/media";
import { Box, Divider, IconButton, Tooltip, useTheme } from "@mui/material";
import { ArrowBack, Edit } from "@mui/icons-material";
import { Body2Typography } from "../shared";
import { RenameDeviceDialog } from "../RenameDeviceDialog";
import { useCameraLabel } from "../../contexts/camera-label-context";

export interface VideoStatsProps {
  quality?: Quality | null;
  deviceName?: string;
  isOutgoing: boolean;
  onBack: () => void;
}

const VideoStats: FC<VideoStatsProps> = (props: VideoStatsProps) => {
  const parseType = (type: IceType): string => {
    switch (type) {
      case "relay":
      case "prflx":
        return "TURN";
      case "srflx":
        return "STUN";
      default:
        return type.toUpperCase();
    }
  };

  const [stats, setStats] = useState<GuiStats | null>(null);

  useEffect(() => {
    const quality = props.quality;
    if (quality == null) {
      return;
    }
    const interval = setInterval(() => setStats(quality.stats), 2000);

    setStats(quality.stats);

    return () => {
      clearInterval(interval);
    };
  }, [props.quality, props.quality?.stats]);

  const { colors } = useTheme();

  const [renameDeviceOpen, setRenameDeviceOpen] = useState(false);
  const { get: getCameraLabel } = useCameraLabel();

  return (
    <VideoStatsStyled>
      {props.deviceName && (
        <RenameDeviceDialog
          open={renameDeviceOpen}
          onClose={() => setRenameDeviceOpen(false)}
          defaultName={props.deviceName}
        />
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        padding="8px 16px 8px 8px"
        width="100%"
      >
        <IconButton
          color="primary"
          onClick={props.onBack}
          data-testid={"video-stats-back-button"}
        >
          <ArrowBack width={"24px"} height={"24px"} />
        </IconButton>
        <Body2Typography color={colors.BlackMediumEmphasis}>
          Video information
        </Body2Typography>
      </Box>
      {props.isOutgoing && (
        <>
          <Divider
            sx={{ color: colors.Grey300, width: "100%", marginY: "4px" }}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            paddingX="16px"
            width="100%"
          >
            <Box paddingTop={"12px"}>
              <Body2Typography color={colors.Grey600}>
                Device name
              </Body2Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height="44px"
              gap="8px"
            >
              <Body2Typography
                color={colors.Grey950}
                sx={{
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {getCameraLabel(props.deviceName)}
              </Body2Typography>
              <Tooltip title="Rename" arrow>
                <IconButton
                  color="primary"
                  sx={{
                    padding: "12px",
                  }}
                  onClick={() => setRenameDeviceOpen(true)}
                >
                  <Edit
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: colors.BrandPrimaryDark,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </>
      )}
      <Divider sx={{ color: colors.Grey300, width: "100%", marginY: "4px" }} />
      <Box padding={"8px 16px"}>
        <Box
          height={"40px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"flex-start"}
        >
          <Body2Typography color={colors.Grey600}>Statistics</Body2Typography>
        </Box>
        {props.quality?.stats !== undefined && (
          <ul>
            <li>
              <Body2Typography>
                FPS: {stats ? Math.round(stats.fps) : "-"}
              </Body2Typography>
            </li>
            <li>
              <Body2Typography>
                Bitrate:{" "}
                {stats ? WebRTCUtil.bitrateToReadable(stats.bitrate) : "-"}
              </Body2Typography>
            </li>
            <li>
              <Body2Typography>
                Packet Loss:{" "}
                {stats ? Math.round(100 * stats.packetLoss) / 100 : "-"}%
              </Body2Typography>
            </li>
            {stats && (
              <li>
                <Body2Typography>
                  Protocol: {stats.protocol.toUpperCase()}
                </Body2Typography>
              </li>
            )}
            {stats && (
              <li>
                <Body2Typography>Type: {parseType(stats.type)}</Body2Typography>
              </li>
            )}
          </ul>
        )}
      </Box>
    </VideoStatsStyled>
  );
};

export default VideoStats;
