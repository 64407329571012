import { useState } from "react";

export const useTelestration = () => {
  const [color, setColor] = useState("#FE38C7");
  const [lastFeedTelestrated, setLastFeedTelestrated] = useState<string | null>(
    null,
  );
  const [isCursorMode, setIsCursorMode] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isEraseLineMode, setIsEraseLineMode] = useState(false);
  const toggleTelestration = () => {
    setIsDrawingMode((prevState) => {
      setIsEraseLineMode(false);
      setIsCursorMode(false);
      return !prevState;
    });
  };
  const toggleEraser = () => {
    setIsDrawingMode(false);
    setIsCursorMode(false);
    setIsEraseLineMode((prevState) => !prevState);
  };

  const toggleCursorMode = () => {
    setIsDrawingMode(false);
    setIsEraseLineMode(false);
    setIsCursorMode((prev) => !prev);
  };

  return {
    color,
    setColor,
    isDrawingMode,
    setIsDrawingMode,
    isEraseLineMode,
    setIsEraseLineMode,
    isCursorMode,
    toggleCursorMode,
    toggleTelestration,
    toggleEraser,
    lastFeedTelestrated,
    setLastFeedTelestrated,
  };
};
