import React, { FC, useEffect, useState } from "react";
import { Radio } from "@proximie/components";
import VideoControlsStyled from "./VideoControls.style";
import { Quality } from "@proximie/media";

export interface VideoControlsProps {
  quality: Quality;
}

const VideoControls: FC<VideoControlsProps> = (props: VideoControlsProps) => {
  const [forceRerender, setForceRerender] = useState(0);

  useEffect(() => {
    function rerender() {
      setForceRerender((prevState) => prevState + 1);
    }

    props.quality.on("substream", rerender);
    props.quality.on("temporal", rerender);
    props.quality.on("resolution", rerender);
    props.quality.on("bitrate", rerender);

    return () => {
      props.quality.off("substream", rerender);
      props.quality.off("temporal", rerender);
      props.quality.off("resolution", rerender);
      props.quality.off("bitrate", rerender);
    };
  }, [props.quality]);

  const controls = props.quality.controls || {};

  return (
    <VideoControlsStyled key={forceRerender}>
      {controls.mode && (
        <Radio
          label="Mode"
          value={controls.mode.value}
          onChange={async (event) => {
            if (controls.mode) {
              await controls.mode.onChange(Number(event));
              setForceRerender((prevState) => prevState + 1);
            }
          }}
          list={controls.mode.options}
        />
      )}
      {controls.temporal && (
        <Radio
          label="FPS"
          disabled={!!controls.mode?.value}
          value={controls.temporal.value}
          onChange={async (event) => {
            if (controls.temporal) {
              await controls.temporal.onChange(Number(event));
            }
          }}
          list={controls.temporal.options}
        />
      )}
      {controls.substream && (
        <Radio
          label="Video Quality"
          disabled={!!controls.mode?.value}
          value={controls.substream.value}
          onChange={async (event) => {
            if (controls.substream) {
              await controls.substream.onChange(Number(event));
            }
          }}
          list={controls.substream.options}
        />
      )}
      {controls.resolution && (
        <Radio
          label="Resolution"
          disabled={!!controls.mode?.value}
          value={controls.resolution.value}
          onChange={async (event) => {
            if (controls.resolution) {
              await controls.resolution.onChange(Number(event));
            }
          }}
          list={controls.resolution.options}
        />
      )}
      {controls.bitrate && (
        <Radio
          label="Bitrate"
          value={controls.bitrate.value}
          onChange={async (event) => {
            if (controls.bitrate) {
              await controls.bitrate.onChange(Number(event));
            }
          }}
          list={controls.bitrate.options}
        />
      )}
    </VideoControlsStyled>
  );
};

export default VideoControls;
