import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
} from "react";
import { GlobalMonitor } from "../../monitoring/GlobalMonitor";
import { UseServerAdapterContext } from "../server-adapter-context";
import { useSessionContext } from "../session-context/session-context";

export interface GlobalMonitorProviderProps {
  children: typeof React.Children | ReactElement | ReactElement[];
}

interface GlobalMonitorContext {
  monitor: GlobalMonitor;
}

const DEFAULT_GLOBAL_MONITOR_CONTEXT: GlobalMonitorContext = {
  monitor: new GlobalMonitor(),
};

const GlobalMonitorContext = createContext<GlobalMonitorContext>(
  DEFAULT_GLOBAL_MONITOR_CONTEXT,
);

export const useGlobalMonitor = () => useContext(GlobalMonitorContext);

export const GlobalMonitorProvider = ({
  children,
}: GlobalMonitorProviderProps) => {
  const serverContext = UseServerAdapterContext();
  const sessionContext = useSessionContext();

  useEffect(() => {
    DEFAULT_GLOBAL_MONITOR_CONTEXT.monitor.context = {
      serverAdapterContext: serverContext,
      sessionContext,
    };
  }, [serverContext, sessionContext]);

  useEffect(() => {
    const logReport = () => {
      console.debug(
        `[GlobalMonitor] report:`,
        DEFAULT_GLOBAL_MONITOR_CONTEXT.monitor.report,
      );
    };

    DEFAULT_GLOBAL_MONITOR_CONTEXT.monitor.subscribe(logReport);
    return () => {
      DEFAULT_GLOBAL_MONITOR_CONTEXT.monitor.unsubscribe(logReport);
    };
  }, []);

  return (
    <GlobalMonitorContext.Provider
      value={{ ...DEFAULT_GLOBAL_MONITOR_CONTEXT }}
    >
      <>{children}</>
    </GlobalMonitorContext.Provider>
  );
};
