import { Controls, ModeType } from "./Quality";
import {
  OUTBOUND_CAMERA_QUALITY_LIMITS,
  MAX_WIDTH,
  MAX_HEIGHT,
  MAX_FRAMERATE,
} from "../../Constants";
import QualityVideoOutgoing, {
  QualityLevel,
  SimulcastLayers,
} from "./QualityVideoOutgoing";
import { JanusJS } from "@proximie/janus-gateway";

type SimulcastLayerInfo = {
  // as a percentage of totalBitratePc.  if zero then the layer is not active
  bitratePc: number;
  scale: number;
};

//LATER - make consitent with screenshare quality
type QualityLevelInfo = {
  // bitrate to allocate the this level as a percentage of the total bitrate
  totalBitratePc: number;
  layers: {
    [SimulcastLayers.High]: SimulcastLayerInfo;
    [SimulcastLayers.Medium]: SimulcastLayerInfo;
    [SimulcastLayers.Low]: SimulcastLayerInfo;
  };
};

const QUALITY_INFO: QualityLevelInfo[] = [
  // level 0 - QualityLevel.Low
  {
    totalBitratePc: 20,
    layers: {
      high: {
        bitratePc: 75,
        scale: 4, // 180p
      },
      medium: {
        bitratePc: 20,
        scale: 8, // 90p,
      },
      low: {
        bitratePc: 5,
        scale: 16, // 45p
      },
    },
  },
  // level 1 - QualityLevel.Medium
  {
    totalBitratePc: 40,
    layers: {
      high: {
        bitratePc: 75,
        scale: 2, // 360p,
      },
      medium: {
        bitratePc: 20,
        scale: 4, // 180p
      },
      low: {
        bitratePc: 5,
        scale: 8, // 90p
      },
    },
  },
  // level 2 - QualityLevel.High
  {
    // we allow 5% below the maximum just in case!
    totalBitratePc: 95,
    layers: {
      high: {
        bitratePc: 75,
        scale: 1, // 720p
      },
      medium: {
        bitratePc: 20,
        scale: 2, // 360p,
      },
      low: {
        bitratePc: 5,
        scale: 4, // 180p
      },
    },
  },
];

export default class QualityVideoOutgoingCamera extends QualityVideoOutgoing {
  //LATER - should the constraints go here?
  private constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      width: { ideal: MAX_WIDTH },
      height: { ideal: MAX_HEIGHT },
      frameRate: { max: MAX_FRAMERATE, ideal: MAX_FRAMERATE },
    },
  };
  static defaultConstraints: MediaStreamConstraints = {
    audio: false,
    video: {
      width: { ideal: MAX_WIDTH },
      height: { ideal: MAX_HEIGHT },
      frameRate: { max: MAX_FRAMERATE, ideal: MAX_FRAMERATE },
    },
  };

  static override getInitialConstraints(
    deviceId?: string,
  ): MediaStreamConstraints {
    const constraints = { ...this.defaultConstraints };
    if (deviceId) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (constraints.video as any).deviceId = { exact: deviceId };
    }
    return constraints;
  }

  public start(streamId: string, handle: JanusJS.PluginHandle) {
    super.start(streamId, handle);

    // start at the highest quality
    this.setResolution(QUALITY_INFO.length - 1);
  }

  public controls: Controls = {
    mode: {
      value: ModeType.Automatic,
      options: [
        { value: ModeType.Manual, label: "Manual" },
        { value: ModeType.Automatic, label: "Automatic" },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoOutCam: auto value=",
          newValue,
        );
        this.controls.mode.value = Number(!!newValue);
        this.reset();
      },
    },
    resolution: {
      value: QualityLevel.High,
      options: [
        {
          value: QualityLevel.High,
          label: QualityLevel[QualityLevel.High],
        },
        {
          value: QualityLevel.Medium,
          label: QualityLevel[QualityLevel.Medium],
        },
        {
          value: QualityLevel.Low,
          label: QualityLevel[QualityLevel.Low],
        },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoOutCam: resolution value=",
          newValue,
        );
        if (newValue < QualityLevel.Low || newValue > QualityLevel.High) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "QualityVideoOutCam: Invalid resolution=",
            newValue,
          );
          return;
        }
        if (!this.isAutoMode) {
          console.debug(
            {
              streamId: this.streamId,
            },
            "QualityVideoOutCam: resolution value=",
            newValue,
          );
          try {
            await this.setResolution(newValue);
          } catch (error) {
            console.warn(
              {
                streamId: this.streamId,
              },
              "QualityVideoOutCam: failed to set resolution - error=",
              error,
            );
          }
        }
      },
    },
  };

  private get isAutoMode() {
    return !!this.controls.mode.value;
  }

  protected limits = OUTBOUND_CAMERA_QUALITY_LIMITS;

  protected downgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.resolution.value === QualityLevel.Low) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG downgrade",
      this.controls.resolution.value,
    );

    this.reset();

    this.setResolution(this.controls.resolution.value - 1);

    return true;
  }

  protected upgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.resolution.value >= QualityLevel.High) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG upgrade",
      this.controls.resolution.value,
    );

    this.reset();

    this.setResolution(this.controls.resolution.value + 1);

    return true;
  }

  get resolution() {
    return this.controls.resolution.value;
  }

  set resolution(value: number) {
    const oldValue = this.controls.resolution.value;
    this.controls.resolution.value = value;
    this.emit("resolution", oldValue, value);
    this.reset();
  }

  async setResolution(value: number): Promise<void> {
    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoOutCam:setResolution - value=",
      value,
    );

    const constraints = this.constraints.video as MediaTrackConstraints;

    const sender = (
      this.handle?.webrtcStuff.pc as unknown as RTCPeerConnection
    ).getSenders()?.[0];
    if (!sender) {
      throw new Error("No sender");
    }

    // restrict bit rate dependent on the current frame rate as a proportion of maximum
    const maxFrameRate = (constraints.frameRate as { max: number })?.max;
    const currentFrameRate = sender.track?.getSettings().frameRate;
    const frameRateMultiplier =
      maxFrameRate && currentFrameRate ? currentFrameRate / maxFrameRate : 1.0;

    const parameters = sender.getParameters();

    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoOutCam:setResolution - original encodings=",
      parameters.encodings,
      frameRateMultiplier,
    );

    parameters.encodings = [
      {
        rid: "h",
        active: this.isSimulcastActive(value, SimulcastLayers.High),
        maxBitrate:
          this.getSimulcastBitrate(value, SimulcastLayers.High) *
          frameRateMultiplier,
        scaleResolutionDownBy: this.getSimulcastScale(
          value,
          SimulcastLayers.High,
        ),
      },
      {
        rid: "m",
        active: this.isSimulcastActive(value, SimulcastLayers.Medium),
        maxBitrate:
          this.getSimulcastBitrate(value, SimulcastLayers.Medium) *
          frameRateMultiplier,
        scaleResolutionDownBy: this.getSimulcastScale(
          value,
          SimulcastLayers.Medium,
        ),
      },
      {
        rid: "l",
        active: this.isSimulcastActive(value, SimulcastLayers.Low),
        maxBitrate:
          this.getSimulcastBitrate(value, SimulcastLayers.Low) *
          frameRateMultiplier,
        scaleResolutionDownBy: this.getSimulcastScale(
          value,
          SimulcastLayers.Low,
        ),
      },
    ];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parameters.degradationPreference = "maintain-framerate";

    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoOutCam:setResolution - new encodings=",
      parameters.encodings,
    );

    await sender.setParameters(parameters);

    this.resolution = value;
  }

  // workaround to get at static initial constraints when we have the instance
  get initialConstraints(): MediaStreamConstraints {
    return QualityVideoOutgoingCamera.getInitialConstraints();
  }

  //LATER - make these private
  override isSimulcastActive(
    level: QualityLevel,
    layer: SimulcastLayers,
  ): boolean {
    return QUALITY_INFO[level].layers[layer].bitratePc !== 0;
  }

  override getSimulcastBitrate(
    level: QualityLevel,
    layer: SimulcastLayers,
  ): number {
    if (typeof this.options.bitrate === "undefined") {
      return 0;
    }

    const bitrate =
      this.options.bitrate * (QUALITY_INFO[level].totalBitratePc / 100);

    return (
      bitrate * ((QUALITY_INFO[level].layers[layer]?.bitratePc || 0) / 100)
    );
  }

  override getSimulcastScale(
    level: QualityLevel,
    layer: SimulcastLayers,
  ): number {
    return QUALITY_INFO[level].layers[layer]?.scale || 0;
  }
}
