import Quality, { QualityMetrics, SummaryStats } from "./Quality";

export default class QualityAudio extends Quality {
  protected generateSummaryStats(report: RTCStatsReport): SummaryStats {
    const summary: SummaryStats = {};
    let ssrc = "";
    const remoteStats: Record<string, RTCStats> = {};

    report.forEach((stat /* RTCStats*/): void => {
      summary.timestamp = stat.timestamp;
      switch (stat.type) {
        case "candidate-pair":
          if (stat.state === "succeeded") {
            summary.totalRoundTripTime = stat.totalRoundTripTime;
            summary.responsesReceived = stat.responsesReceived;
            summary.availableOutgoingBitrate = stat.availableOutgoingBitrate;
          }
          break;
        case "transport":
          summary.bytesTransferred = stat.bytesSent;
          summary.packetsTransferred = stat.packetsSent;
          break;
        case "outbound-rtp":
          summary.totalProcessingTime = stat.totalEncodeTime;
          ssrc = stat.ssrc;
          break;
        case "remote-inbound-rtp":
          // store all the remote data so we can access it by SSRC later
          remoteStats[stat.ssrc] = stat;
          break;
        default:
        // ignore everything else
      }
    });

    if (remoteStats[ssrc]) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      summary.packetsLost = (remoteStats[ssrc] as any).packetsLost;
    }

    return summary;
  }

  public static getInitialConstraints(): MediaStreamConstraints {
    throw new Error("Not implemented for audio");
  }

  protected override debug(metrics: QualityMetrics): void {
    console.debug(
      { streamId: this.streamId },
      `AUDIO STATS ${JSON.stringify(metrics)}`,
    );
  }

  protected override upgrade(): boolean {
    return true;
  }

  protected override downgrade(): boolean {
    return true;
  }
}
