export const OBJECT_FIT_CONTAIN = "contain";
export const OBJECT_FIT_COVER = "cover";
export const DEFAULT_TELESTRATION_HEIGHT = 720;

export const IP_SUPPORT_URL =
  "https://proximie.atlassian.net/servicedesk/customer/portal/3/article/2387542060?src=-1824871589";

export enum PARTICIPANT_STATUS {
  Online,
  Invited,
  NotInvited,
}

export enum ActiveTab {
  NONE,
  PARTICIPANTS,
  CHAT,
  SESSIONINFO,
  LOGOUT,
  PTZPANEL,
  SUPPORT,
}

const SPOTLIGHT_FEATURE_FLAGS = {
  ROLLOUT: "ff_spotlight_rollout",
  EXPERIMENTAL: "ff_spotlight_experimental",
} as const;

export const FeatureFlags = {
  SESSION_OWNER_TRANSFER_MILESTONE_2: "ff_session_owner_transfer_ms2",
  SPOTLIGHT: SPOTLIGHT_FEATURE_FLAGS.ROLLOUT,
  WATCH_RTC: "ff_watch_rtc",
} as const;
