import { Box, styled } from "@mui/material";

export const TroubleShootingReportStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.Grey50};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  width: 1100px;

  max-height: 798px;
  margin-top: calc((max(798px, 100vh) - 110vh) / 2);

  overflow: hidden;
`;
