import { Typography } from "@proximie/components";
import React, { FC, useEffect, useState } from "react";
import VideoStatsStyled from "./VideoStats.style";
import { WebRTCUtil, Quality, GuiStats, IceType } from "@proximie/media";
export interface VideoStatsProps {
  quality: Quality;
}

const VideoStats: FC<VideoStatsProps> = (props: VideoStatsProps) => {
  const parseType = (type: IceType): string => {
    switch (type) {
      case "relay":
      case "prflx":
        return "TURN";
      case "srflx":
        return "STUN";
      default:
        return type.toUpperCase();
    }
  };

  const [stats, setStats] = useState<GuiStats | null>(null);

  useEffect(() => {
    const interval = setInterval(() => setStats(props.quality.stats), 2000);

    setStats(props.quality.stats);

    return () => {
      clearInterval(interval);
    };
  }, [props.quality.stats]);

  return (
    <VideoStatsStyled>
      <ul>
        <li>
          <Typography variant="body2">
            FPS: {stats ? Math.round(stats.fps) : "-"}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Bitrate: {stats ? WebRTCUtil.bitrateToReadable(stats.bitrate) : "-"}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Packet Loss:{" "}
            {stats ? Math.round(100 * stats.packetLoss) / 100 : "-"}%
          </Typography>
        </li>
        {stats && (
          <li>
            <Typography variant="body2">
              Protocol: {stats.protocol.toUpperCase()}
            </Typography>
          </li>
        )}
        {stats && (
          <li>
            <Typography variant="body2">
              Type: {parseType(stats.type)}
            </Typography>
          </li>
        )}
      </ul>
    </VideoStatsStyled>
  );
};

export default VideoStats;
