import EventEmitter from "events";
import { MonitorContext } from "./MonitorContext";

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const REPORT_KEY = "report";

export type ReportCallback<Report> = (report: Report) => void;

export type MonitorOptions<
  SubReports extends Record<string, unknown> | unknown,
> = {
  subMonitors?: Record<string, Monitor<SubReports>>;
  label?: string;
  intervalMs?: number;
  enabled?: boolean;
};

export abstract class Monitor<
  Report extends Record<string, unknown> | unknown,
  SubReports extends Record<string, unknown> | unknown = unknown,
> extends EventEmitter {
  private _report: DeepPartial<Report> | undefined;
  private readonly _subMonitors: Record<string, Monitor<SubReports>>;
  private readonly _label?: string;
  private readonly intervalMs: number;
  public enabled: boolean;
  private _context?: MonitorContext;

  public constructor({
    subMonitors,
    label,
    intervalMs,
    enabled,
  }: MonitorOptions<SubReports>) {
    super();
    this._subMonitors = subMonitors ?? {};
    this._label = label;
    this.intervalMs = intervalMs ?? 2000;
    this.enabled = enabled ?? true;

    for (const sm of Object.values(this.subMonitors)) {
      sm.addListener(REPORT_KEY, () => this.emit(REPORT_KEY));
    }
  }

  public start() {
    this.traverse((m) => m.performRun());
  }

  private async performRun(): Promise<void> {
    if (!this.enabled) {
      setTimeout(this.performRun.bind(this), this.intervalMs);
      return;
    }

    const report = await this.run();
    if (report) {
      this.report = report;
      setTimeout(this.performRun.bind(this), this.intervalMs);
    }
  }

  get label(): string {
    return this._label ?? this.constructor.name;
  }

  get subMonitors(): Record<string, Monitor<SubReports>> {
    return this._subMonitors;
  }

  public get report(): DeepPartial<Report> {
    return {
      ...(this._report ?? {}),
      ...Object.entries(this.subMonitors).reduce((acc, [key, value]) => {
        acc[key] = value.report;
        return acc;
      }, {} as any),
    };
  }

  protected set report(report: DeepPartial<Report>) {
    this._report = report;
    this.emit(REPORT_KEY);
  }

  public subscribe(callback: ReportCallback<DeepPartial<Report>>) {
    this.on(REPORT_KEY, () => callback(this.report));
  }

  public unsubscribe(callback: ReportCallback<DeepPartial<Report>>) {
    this.off(REPORT_KEY, () => callback(this.report));
  }

  public get context(): MonitorContext | undefined {
    return this._context;
  }

  public set context(context: MonitorContext | undefined) {
    this.traverse((m) => (m._context = context));
  }

  private traverse(fn: (m: Monitor<unknown>) => void) {
    const sms = Object.values(this.subMonitors);
    if (sms.length) {
      for (const sm of sms) {
        sm.traverse(fn);
      }
    }
    fn(this);
  }

  run(): DeepPartial<Report> | Promise<DeepPartial<Report>> | undefined {
    return undefined;
  }
}

export abstract class LeafMonitor<
  Report extends Record<string, unknown>,
> extends Monitor<Report> {
  abstract run():
    | DeepPartial<Report>
    | Promise<DeepPartial<Report>>
    | undefined;
}
