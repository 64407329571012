import React, { FC, SyntheticEvent, useCallback, useState } from "react";
import { Flexbox } from "@proximie/components";
import {
  Box,
  Button as MUIButton,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Edit, VideoCall } from "@mui/icons-material";
import { Wrapper } from "./AddVideo.styles";
import { UseServerAdapterContext } from "../../contexts/server-adapter-context";
import { useCameraLabel } from "../../contexts/camera-label-context";
import { Connection, Device, DeviceDcp, DeviceMetadata } from "@proximie/media";
import { RenameDeviceDialog } from "../RenameDeviceDialog";

export interface AddVideoProps {
  isCondensed?: boolean;
  sendCamera: (device: Device) => void;
  videos: Connection[];
  checkCam?: () => void;
}

type Devices = Record<string, Device[]>;

const AddVideo: FC<AddVideoProps> = ({
  isCondensed = true,
  sendCamera,
  videos,
  checkCam,
}: AddVideoProps): JSX.Element => {
  const context = UseServerAdapterContext();
  const { get: getCameraLabel } = useCameraLabel();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [availableDevices, setAvailableDevices] = useState<Devices>({});

  const updateDeviceList = useCallback(() => {
    const list = context?.cameraMonitor?.deviceList ?? [];

    const devices: Devices = {};
    list.forEach((device: Device): void => {
      devices[device.deviceId] = [...(devices[device.deviceId] || []), device];
    });

    setAvailableDevices(devices);
  }, [context]);

  const addDeviceList = useCallback(
    (device: Device): void => {
      device.on("state", updateDeviceList);
      updateDeviceList();
    },
    [updateDeviceList],
  );

  const removeDeviceList = useCallback(
    (device: Device): void => {
      device.off("state", updateDeviceList);
      updateDeviceList();
    },
    [updateDeviceList],
  );

  const openVideoDeviceList = (event: SyntheticEvent): void => {
    checkCam?.();
    setAnchorEl(event.currentTarget);

    if (context?.cameraMonitor) {
      context.cameraMonitor.on("added", addDeviceList);
      context.cameraMonitor.on("removed", removeDeviceList);

      context.cameraMonitor.deviceList.forEach((device: Device): void => {
        device.on("state", updateDeviceList);
      });

      updateDeviceList();
    }
  };

  const closeVideoDeviceList = (): void => {
    setAnchorEl(null);

    if (context?.cameraMonitor) {
      context.cameraMonitor.off("added", addDeviceList);
      context.cameraMonitor.off("removed", removeDeviceList);

      context.cameraMonitor.deviceList.forEach((device: Device): void => {
        device.off("state", updateDeviceList);
      });
    }
  };

  const selectVideoDevice = (device: Device): void => {
    closeVideoDeviceList();
    sendCamera(device);
  };

  const isSelected = (device: Device): boolean => {
    return !!videos.find((connection: Connection): boolean => {
      if (!(device instanceof DeviceDcp)) {
        return !!connection.getDeviceById(device.deviceId);
      }

      if (!connection.params?.devices) {
        return !!connection.getDeviceById(device.deviceId);
      }

      return !!connection.params.devices.find(
        //eslint-disable-next-line sonarjs/no-identical-functions
        (metadata: DeviceMetadata): boolean =>
          !!(
            metadata.deviceId === device.deviceId &&
            metadata.component === device.options.component
          ),
      );
    });
  };

  const isDisabled = (device: Device): boolean => {
    if (!(device instanceof DeviceDcp)) {
      // we can have multiple instances of local devices
      return false;
    }

    const stream = device.state?.STREAM;
    //LATER - pre-LMS backwards compatibility
    if (stream?.streaming || (stream?.mode && stream?.mode !== "none")) {
      return true;
    }

    return !!videos.find((connection: Connection): boolean => {
      if (!connection.params?.devices) {
        return false;
      }
      return !!connection.params.devices.find(
        //eslint-disable-next-line sonarjs/no-identical-functions
        (metadata: DeviceMetadata): boolean =>
          !!(
            metadata.deviceId === device.deviceId &&
            metadata.component === device.options.component
          ),
      );
    });
  };

  const renderDevice = (list: Device[]): JSX.Element[] => {
    list.sort((a: Device, b: Device): number => {
      return a.label.localeCompare(b.label);
    });

    let result = list.map((device: Device): JSX.Element => {
      const cameraLabel = getCameraLabel(device.label);
      return (
        <Box
          key={device.uniqueId}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={0}
          margin={0}
          gap={"8px"}
          paddingLeft={"0"}
          paddingRight={"8px"}
        >
          <MenuItem
            sx={{
              paddingY: "12px",
              paddingLeft: "16px",
              width: "216px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
            selected={isSelected(device)}
            disabled={isDisabled(device)}
            onClick={() => selectVideoDevice(device)}
            data-cy="add-camera-video"
          >
            {cameraLabel}
          </MenuItem>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => openDeviceRename(device.label)}
            padding={0}
            margin={0}
          >
            <IconButton
              sx={{ padding: "12px" }}
              data-testid={`rename-device-button-${device.label}`}
            >
              <Edit
                width={"20px"}
                height={"20px"}
                sx={{ color: colors.BrandPrimaryDark }}
              />
            </IconButton>
          </Box>
        </Box>
      );
    });
    if (list[0] instanceof DeviceDcp) {
      result = [
        <Divider role="presentation" key={`${list[0].deviceId}-START`}>
          {getCameraLabel(list[0].options.dcpDevice.devicePresence.label)}
        </Divider>,
        ...result,
        <Divider role="presentation" key={`${list[0].deviceId}-END`} />,
      ];
    }
    return result;
  };

  const { colors, ...theme } = useTheme();
  // Device Rename Stuff
  const [deviceRenameOpen, setDeviceRenameOpen] = useState<boolean>(false);
  const [deviceToRename, setDeviceToRename] = useState<string | null>(null);
  const openDeviceRename = (deviceToRename: string) => {
    setDeviceToRename(deviceToRename);
    setDeviceRenameOpen(true);
  };
  const closeDeviceRename = () => {
    setDeviceToRename(null);
    setDeviceRenameOpen(false);
  };

  return (
    <>
      {deviceToRename && (
        <RenameDeviceDialog
          open={deviceRenameOpen}
          onClose={closeDeviceRename}
          defaultName={deviceToRename}
        />
      )}
      <Menu
        id="camera-selection"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={
          isCondensed
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : { vertical: "center", horizontal: "center" }
        }
        transformOrigin={
          isCondensed
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : { vertical: "center", horizontal: "center" }
        }
        onClose={closeVideoDeviceList}
        keepMounted
        autoFocus
        MenuListProps={{
          sx: {
            padding: "8px 0",
          },
        }}
      >
        {Object.keys(availableDevices).length === 0 && (
          <MenuItem>No camera devices found...</MenuItem>
        )}
        {Object.keys(availableDevices).map((deviceId: string) =>
          renderDevice(availableDevices[deviceId]),
        )}
      </Menu>
      {isCondensed && (
        <Tooltip title="Add video device" placement="top">
          <MUIButton
            aria-label="Add video device"
            size="small"
            color="inherit"
            data-cy="camera-add-button-small"
            onClick={openVideoDeviceList}
          >
            <VideoCall style={{ width: "32px", height: "32px" }} />
          </MUIButton>
        </Tooltip>
      )}
      {!isCondensed && (
        <Wrapper>
          <MUIButton
            sx={{
              border: 2,
              borderStyle: "dashed",
              borderColor: theme.palette.text.secondary,
            }}
            aria-label="Add video device"
            title="Add video device"
            size="small"
            color="primary"
            data-cy="camera-add-button-big"
            className="camera-add-button-big"
            onClick={openVideoDeviceList}
          >
            <Flexbox
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <VideoCall fontSize={"large"} />
                <Typography
                  variant="h6"
                  component={"div"}
                  className="add-video-text"
                >
                  Add video device
                </Typography>
              </Box>
            </Flexbox>
          </MUIButton>
        </Wrapper>
      )}
    </>
  );
};

export default AddVideo;
