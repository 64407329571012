import React from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { FeatureFlags, PARTICIPANT_STATUS } from "../constants";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MicIcon from "@mui/icons-material/MicOutlined";
import MicOffIcon from "@mui/icons-material/MicOff";
import CircularProgress from "@mui/material/CircularProgress";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { Permission, Role } from "../../utils/checkPermission";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { ConnectionMetadata, DeviceType } from "@proximie/media";

export interface ParticipantInfo {
  name: string;
  userUUID?: string;
  userStatus: PARTICIPANT_STATUS;
  role?: string;
  isMuted?: boolean;
  isActive?: boolean;
  isMe?: boolean; //to display (Me) tag
  isLocal?: boolean;
  metadata?: ConnectionMetadata;
  streamId?: string;
}

export interface ParticipantProps extends ParticipantInfo {
  // eslint-disable-next-line @typescript-eslint/ban-types
  inviteUser?: () => void;
  setMuteState?: (muted: boolean) => void;
}

const Participant = ({
  name,
  role,
  userStatus,
  isMuted = false,
  isActive = false,
  isMe = false,
  isLocal = false,
  inviteUser,
  setMuteState,
  metadata,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ParticipantProps): JSX.Element => {
  const { colors } = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isInviting, setIsInviting] = React.useState(false);
  const { ShowForPermission } = useSessionContext();
  const isSessionOwnerTransferEnabled = useFlag(
    FeatureFlags.SESSION_OWNER_TRANSFER_MILESTONE_2,
  );

  const open = Boolean(anchorEl);
  const openContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleInviteUserClick = () => {
    if (isInviting) return;
    if (!inviteUser) return;
    setIsInviting(true);
    inviteUser();
  };
  const handleReinviteClick = () => {
    handleClose();
    if (!inviteUser) return;
    inviteUser();
  };
  const handleMuteUserClick = () => {
    if (setMuteState) {
      if (isMe) {
        console.log(`call mute self with ${!isMuted}`);
        setMuteState(!isMuted);
        // eslint-disable-next-line sonarjs/no-collapsible-if
      } else if (!isMuted) {
        setMuteState(true);
      }
    }
  };

  const getContextMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleReinviteClick}>
          <Typography variant={"body2"}>Re-invite User</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const getButton = () => {
    if (userStatus === PARTICIPANT_STATUS.NotInvited)
      return (
        <ShowForPermission permission={Permission.INVITE_PARTICIPANTS}>
          <Button
            size={"compact"}
            variant="outlined"
            onClick={handleInviteUserClick}
          >
            {isInviting ? (
              <CircularProgress size={24} data-testid={"invite-loading"} />
            ) : (
              <AddIcon />
            )}
          </Button>
        </ShowForPermission>
      );
    if (userStatus === PARTICIPANT_STATUS.Invited)
      return (
        <ShowForPermission permission={Permission.INVITE_PARTICIPANTS}>
          <Button variant="outlined" size={"compact"} onClick={openContextMenu}>
            <MoreHorizIcon />
          </Button>
          {getContextMenu()}
        </ShowForPermission>
      );
    if (userStatus === PARTICIPANT_STATUS.Online && isMuted)
      return (
        <Button
          variant="outlined"
          size={"compact"}
          onClick={handleMuteUserClick}
          disabled={isMe ? false : true}
        >
          <MicOffIcon />
        </Button>
      );
    if (userStatus === PARTICIPANT_STATUS.Online && !isMuted)
      return (
        <ShowForPermission permission={Permission.MUTE_PARTICIPANTS}>
          <Button
            color={isActive ? "success" : "primary"}
            variant="outlined"
            size={"compact"}
            onClick={handleMuteUserClick}
          >
            <MicIcon />
          </Button>
        </ShowForPermission>
      );
  };
  const getSubtitle = () => {
    const subtitle = [];

    if (metadata?.deviceType === DeviceType.PxLens) {
      subtitle.push("Added by " + name);
    } else {
      if (isLocal) subtitle.push("You");

      if (role) {
        if (isSessionOwnerTransferEnabled) {
          if (role !== Role.Participant) {
            subtitle.push(role);
          }
        } else if (role === Role.Owner) {
          subtitle.push(role);
        }
      }
    }

    if (subtitle.length)
      return (
        <Typography variant={"caption"}>({subtitle.join(", ")})</Typography>
      );
    return "";
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={250}
      textAlign={"center"}
      py={0.5}
      data-testid={"participant-row"}
    >
      <Box textAlign={"left"} sx={{ lineHeight: 1, width: 180 }}>
        <Typography variant={"body2"} title={name} noWrap>
          {metadata?.deviceType === DeviceType.PxLens ? "PxLens" : name}
        </Typography>
        <Typography color={colors.BlackInactive}>{getSubtitle()}</Typography>
      </Box>
      <Box sx={{ marginRight: "-16px" }}>{getButton()}</Box>
    </Box>
  );
};

export default Participant;
