import React from "react";
import { TroubleShootingMenuStyled } from "./TroubleShootingMenu.style";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  TroubleShootingMenuOverviewContainer,
  useTroubleShooting,
} from "../index";
import { TroubleShootingMenuAccordionContainer } from "./TroubleShootingMenuAccordion";
import { Close, Quiz } from "@mui/icons-material";
import {
  Subtitle2Typography,
  Subtitle3Typography,
} from "../shared/CustomTypography";

export type TroubleShootingMenuProps = {
  anchor: HTMLElement | null;
  open: boolean;
};

export const TroubleShootingMenu = ({
  anchor,
  open,
}: TroubleShootingMenuProps) => {
  const {
    setMenuOpen,
    setNetworkExpanded,
    setVideoExpanded,
    setFirewallExpanded,
    setDetailedReportOpen,
    openSupportTicket,
  } = useTroubleShooting();
  const { colors } = useTheme();

  const closeMenu = () => {
    setNetworkExpanded(false);
    setVideoExpanded(false);
    setFirewallExpanded(false);
    setMenuOpen(false);
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={closeMenu}
    >
      <TroubleShootingMenuStyled
        anchorEl={anchor}
        open={open}
        placement={"top"}
        modifiers={[
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              padding: {
                top: 24,
                bottom: 8,
                left: 16,
                right: 16,
              },
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 6],
            },
          },
        ]}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
          margin={"0"}
          padding={"0"}
        >
          <Subtitle2Typography>Troubleshooting</Subtitle2Typography>
          <IconButton onClick={closeMenu}>
            <Close
              style={{
                width: "24px",
                height: "24px",
                color: colors.BlackMediumEmphasis,
              }}
            />
          </IconButton>
        </Box>
        <TroubleShootingMenuOverviewContainer />
        <TroubleShootingMenuAccordionContainer />
        <Button
          sx={{
            px: "0",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          variant={"text"}
          startIcon={<Quiz />}
          disableRipple={true}
          onClick={() => setDetailedReportOpen(true)}
        >
          More helpful information
        </Button>
        <Subtitle3Typography
          sx={{
            display: "flex",
            gap: "2px",
            letterSpacing: "0.035px",
            color: colors.Grey600,
          }}
        >
          Still having trouble?
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openSupportTicket}
          >
            Contact support
          </span>
        </Subtitle3Typography>
      </TroubleShootingMenuStyled>
    </ClickAwayListener>
  );
};
