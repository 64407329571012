import { Monitor } from "./Monitor";
import { NetworkMonitor, NetworkReport } from "./monitors/NetworkMonitor";

export type GlobalReport = {
  network: NetworkReport;
};

type SubReport = NetworkReport;

export class GlobalMonitor extends Monitor<GlobalReport, SubReport> {
  constructor() {
    const networkMonitor = new NetworkMonitor();

    super({
      subMonitors: {
        network: networkMonitor,
      },
    });

    (window as any)["gm"] = this;

    this.start();
  }
}
