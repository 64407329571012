import { Box, styled } from "@mui/material";

export const TroubleShootingReportPanelStyled = styled(Box)<{
  flex?: string;
  overflowY?: string;
  minHeight?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  flex: ${({ flex }) => flex ?? "1 0 0"};
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.White};
  border-radius: 4px;
  padding: ${({ padding }) => padding ?? "12px"};
  overflow-y: ${({ overflowY }) => overflowY ?? "visible"};
  min-height: ${({ minHeight }) => minHeight ?? "unset"};
`;
