import { ModeType } from "./Quality";
import { OUTBOUND_SCREENSHARE_QUALITY_LIMITS } from "../../Constants";
import QualityVideoOutgoing, {
  QualityLevel,
  SimulcastLayers,
} from "./QualityVideoOutgoing";
import { JanusJS } from "@proximie/janus-gateway";

enum BitrateOptions {
  Low = 0,
  Lowish = 1,
  Highish = 2,
  High = 3,
}

const BitrateLookup: Record<number, number> = {
  [BitrateOptions.Low]: 500000,
  [BitrateOptions.Lowish]: 1000000,
  [BitrateOptions.Highish]: 1500000,
  [BitrateOptions.High]: 2000000,
};

export default class QualityVideoOutgoingScreenshare extends QualityVideoOutgoing {
  /**
   * We get the stream at 24 FPS to define the max "Capability" of the stream,
   * then we change it to 12 to define the default "constraint"
   * that will be used to re-requestOutgoingVideoQuality after the stream starts.
   * this step will re-set the framerate to 12 in constraints and actual stream
   * and allow changing FPS between 6 and 24.
   */
  private constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      frameRate: { max: 12, ideal: 12 },
      cursor: "always",
    },
  } as MediaStreamConstraints;

  static override getInitialConstraints(): MediaStreamConstraints {
    return {
      audio: false,
      video: {
        frameRate: { max: 24, ideal: 24 },
        // Typescript definition do not define cursor
        cursor: "always",
      },
    } as unknown as MediaStreamConstraints;
  }

  public controls = {
    mode: {
      value: ModeType.Automatic,
      options: [
        { value: ModeType.Manual, label: "Manual" },
        { value: ModeType.Automatic, label: "Automatic" },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInCam: auto value=",
          newValue,
        );
        this.controls.mode.value = newValue;
        this.reset();
      },
    },
    bitrate: {
      value: BitrateOptions.High,
      options: [
        {
          value: BitrateOptions.High,
          label: String(BitrateLookup[BitrateOptions.High]),
        },
        {
          value: BitrateOptions.Highish,
          label: String(BitrateLookup[BitrateOptions.Highish]),
        },
        {
          value: BitrateOptions.Lowish,
          label: String(BitrateLookup[BitrateOptions.Lowish]),
        },
        {
          value: BitrateOptions.Low,
          label: String(BitrateLookup[BitrateOptions.Low]),
        },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInScr: bitrate value=",
          newValue,
        );
        if (newValue < BitrateOptions.Low || newValue > BitrateOptions.High) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "QualityVideoInCam: invalid bitrate value=",
            newValue,
          );
          return;
        }
        if (!this.isAutoMode) {
          try {
            await this.setBitrate(newValue);
          } catch {
            //ignore error
          }
        }
      },
    },
  };

  private get isAutoMode() {
    return !!this.controls.mode.value;
  }

  protected limits = OUTBOUND_SCREENSHARE_QUALITY_LIMITS;

  get bitrate() {
    return this.controls.bitrate.value;
  }

  set bitrate(value: number) {
    const oldValue = this.controls.bitrate.value;
    this.controls.bitrate.value = value;
    this.emit("bitrate", oldValue, value);
    this.reset();
  }

  setBitrate(value: number): Promise<void> {
    return new Promise((resolve, reject) => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "VideoIn:setBitrate - bitrate=",
        value,
      );
      const body = {
        request: "configure",
        bitrate: BitrateLookup[value],
      };

      this.handle?.send({
        message: body,
        success: () => {
          console.debug(
            {
              streamId: this.streamId,
            },
            "VideoIn:setBitrate - done",
          );
          this.bitrate = value;
          resolve();
        },
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => {
          reject(new Error(error));
        },
      } as unknown as JanusJS.PluginMessage);
    });
  }

  protected downgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.bitrate.value === BitrateOptions.Low) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG downgrade",
      this.controls.bitrate.value,
    );

    this.reset();

    this.setBitrate(this.controls.bitrate.value - 1);

    return true;
  }

  protected upgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.bitrate.value >= BitrateOptions.High) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG upgrade",
      this.controls.bitrate.value,
    );

    this.reset();

    this.setBitrate(this.controls.bitrate.value + 1);

    return true;
  }

  // workaround to get at static initial constraints when we have the instance
  get initialConstraints(): MediaStreamConstraints {
    return QualityVideoOutgoingScreenshare.getInitialConstraints();
  }

  override isSimulcastActive(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): boolean {
    return false;
  }

  override getSimulcastBitrate(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): number {
    return 0;
  }

  override getSimulcastScale(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): number {
    return 0;
  }
}
