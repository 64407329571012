/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  AspectRatio,
  ChevronRight,
  Fullscreen,
  FullscreenExit,
  Info,
  MoreVert,
  OpenWith,
  PictureInPicture,
  Settings,
  VideocamOff,
} from "@mui/icons-material";
import SecurityIcon from "@mui/icons-material/Security";
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  useTheme,
} from "@mui/material";
import { SnackbarContext, Typography } from "@proximie/components";
import { liveApiSockets } from "@proximie/dataregion-api";
import { Connection, ConnectionType } from "@proximie/media";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import SocketIoClientWrapper from "../../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { UseSocketIoContext } from "../../contexts/socket-io-context";
import SessionParams from "../../models/SessionParams";
import { PtzUser } from "../../utils/PtzUser";
import { Permission } from "../../utils/checkPermission";
import PtzPermissionLabel from "../PtzPermissionLabel/PtzPermissionLabel";
import PtzStatusLabel from "../PtzStatusLabel/PtzStatusLabel";
import { TelestrationCanvas } from "../TelestrationCanvas/TelestrationCanvas";
import VideoControls from "../VideoControls/VideoControls";
import VideoStats from "../VideoStats/VideoStats";
import {
  ActiveTab,
  DEFAULT_TELESTRATION_HEIGHT,
  FeatureFlags,
  OBJECT_FIT_CONTAIN,
  OBJECT_FIT_COVER,
} from "../constants";
import { SpotlightIcon } from "../icons/SpotlightIcon/SpotlightIcon";
import {
  VideoControlsStyled,
  VideoMediaStyled,
  VideoStyled,
} from "./Video.style";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { useSessionUserSettings } from "../../hooks/useSessionUserSettings/useSessionUserSettings";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { RemoveSpotlightButton } from "../RemoveSpotlightButton/RemoveSpotlightButton";
import { RemoveSpotlightIcon } from "../RemoveSpotlightIcon/RemoveSpotlightIcon";
import { useSpotlightDialog } from "../SpotlightDialog/SpotlightDialog";
import { SessionFeedView } from "../../models/SessionContext";
import { useTranslation } from "react-i18next";
import { user } from "@proximie/api";
import { useCameraLabel } from "../../contexts/camera-label-context";

enum VideoMenuTab {
  main = 0,
  resolution = 1,
  stats = 2,
}

export interface VideoProps {
  connection: Connection;
  streamId: string;
  index: number;
  socket: SocketIoClientWrapper;
  user: user.User;
  isDrawingMode: boolean;
  isEraseLineMode: boolean;
  isCursorMode: boolean;
  color: string;
  brushWidth: number;
  numberOfVideos: number;
  sessionParams: SessionParams;
  onMaximiseUpdateClick: (streamId: string | null) => void;
  isMaximised: boolean;
  ptzUser: PtzUser | null;
  setActiveTab: React.Dispatch<React.SetStateAction<ActiveTab>>;
  activeTab?: ActiveTab;
  isPtzSelected?: boolean;
  selectPTZ?: (streamId: string) => void;
  removeCameraIntent?: (deviceId: string) => void;
  editPrivacyMask?: (streamId: string) => void;
  videoWithMask: boolean;
  checkIfVideoWithMasks: (streamId: string) => void;
  videoFeedBoundaryElement?: HTMLElement | null;
  lastFeedTelestrated: string | null;
  setLastFeedTelestrated: React.Dispatch<React.SetStateAction<string | null>>;
}

const getAspectRatio = (connection: Connection) => {
  const videoTracks = connection.stream?.getVideoTracks();
  if (videoTracks?.[0]) {
    return videoTracks[0].getSettings().aspectRatio || null;
  } else {
    return null;
  }
};

const listItemIconStyle = { minWidth: "44px" };

const Video: FC<VideoProps> = (props: VideoProps) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { get: getCameraLabel } = useCameraLabel();

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [telestrationScale, setTelestrationScale] = useState(1);
  const [containerAspect, setContainerAspect] = useState(1);
  const [objectFit, setObjectFit] = useState<
    typeof OBJECT_FIT_CONTAIN | typeof OBJECT_FIT_COVER
  >(OBJECT_FIT_CONTAIN);

  const sessionContext = useSessionContext();
  const menuButtonRef = useRef(null);

  const { showSnackbar } = SnackbarContext.useSnackbarContext();
  const socketIOWrapper = UseSocketIoContext();
  const { user, role } = useSessionContext();

  const canUserEditAnnotationWidth = useFlag(
    FeatureFlags.ANNOTATIONS_BETA_ENABLED,
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [tab, setTab] = React.useState(VideoMenuTab.main);
  const [isVideoPaused, setIsVideoPaused] = React.useState<boolean>(false);
  const [isDegraded, setIsDegraded] = React.useState<boolean>(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const [aspectRatio, setAspectRatio] = useState(
    getAspectRatio(props.connection),
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const setScale = () => {
      if (videoContainerRef.current && aspectRatio) {
        const width = videoContainerRef.current.offsetWidth;
        const height = videoContainerRef.current.offsetHeight;
        let contAspect = width / height;
        let scale;
        const telestrationWidth = DEFAULT_TELESTRATION_HEIGHT * aspectRatio;
        if (objectFit === OBJECT_FIT_CONTAIN) {
          scale =
            aspectRatio > contAspect
              ? width / telestrationWidth
              : height / DEFAULT_TELESTRATION_HEIGHT;
        } else {
          scale =
            aspectRatio < contAspect
              ? width / telestrationWidth
              : height / DEFAULT_TELESTRATION_HEIGHT;

          contAspect = aspectRatio;
        }

        setContainerAspect(contAspect);
        setTelestrationScale(scale);
      }
    };
    setScale();
    window.addEventListener("resize", setScale);

    return () => {
      window.removeEventListener("resize", setScale);
    };
  }, [
    objectFit,
    aspectRatio,
    props.numberOfVideos,
    props.activeTab,
    props.isMaximised,
  ]);

  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? "simple-popover" : undefined;

  const MediaRef = React.useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (props.connection.stream && MediaRef.current) {
      MediaRef.current.srcObject = props.connection.stream;
      MediaRef.current
        .play()
        .then(() => setAspectRatio(getAspectRatio(props.connection)));
    }
  }, [props.connection.stream, props.connection]);
  useEffect(() => {
    setIsVideoPaused(MediaRef.current?.paused || false);
  }, []);

  const isVideoSpotlighted = useCallback(() => {
    return (
      props?.connection?.streamId ===
      sessionContext.spotlight.spotlightedFeed?.streamId
    );
  }, [
    props?.connection?.streamId,
    sessionContext.spotlight.spotlightedFeed?.streamId,
  ]);

  const handleVideoClick = (
    e: React.MouseEvent<HTMLVideoElement, MouseEvent>,
  ) => {
    e.preventDefault();
  };

  useEffect(() => {
    // if its an inbound video then check we're still receiving data for it,
    // if we don't then close the local connection and wait for the monitor room
    // polling to initiate the stream again

    if (
      !props.connection.isOutgoingVideo ||
      props.connection.connectionType === ConnectionType.DcpVideoOutgoing
    ) {
      let prevTime = 0;
      const timerId = setInterval(() => {
        const videoElement = MediaRef.current;
        if (videoElement) {
          if (videoElement.currentTime > prevTime) {
            prevTime = videoElement.currentTime;
          } else {
            console.warn(
              { streamId: props.streamId },
              "Video has stalled - element not updated",
            );
            showSnackbar({
              message: {
                body: t(
                  "common.components.snackbar.messages.videoStalledFailure",
                ),
              },
              severity: "warning",
            });
            props.connection.close(new Error("Video has stalled"));
          }
        }
      }, 30000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, []);

  useEffect(() => {
    function setDegradedFlag(value: boolean) {
      console.warn({ streamId: props.streamId }, "Degraded value=", value);
      setIsDegraded(value);
    }

    props.connection.quality?.on("degraded", setDegradedFlag);

    return () => {
      props.connection.quality?.off("degraded", setDegradedFlag);
    };
  }, [props.connection.quality, setIsDegraded]);

  useEffect(() => {
    const handleVideoKicked = (event: {
      kickingUserRole: string;
      kickingUserID: number;
      kickedUserId: number;
    }) => {
      if (
        user?.profileId === event.kickedUserId &&
        user?.profileId !== event.kickingUserID
      ) {
        const role = event.kickingUserRole?.toLowerCase();
        showSnackbar({
          message: {
            body:
              role === "Owner"
                ? t("common.components.snackbar.messages.videoKicked", {
                    role,
                  })
                : t(
                    "common.components.snackbar.messages.videoKickedSessionOwner",
                  ),
          },
          severity: "info",
          autoHideDuration: 4000,
        });
      }
    };

    //someone closed my video
    socketIOWrapper.onBroadcast(
      liveApiSockets.MediaSessionEventBroadcastTopics.videoKicked,
      handleVideoKicked,
    );

    return () => {
      socketIOWrapper.offBroadcast(
        liveApiSockets.MediaSessionEventBroadcastTopics.videoKicked,
        handleVideoKicked,
      );
    };
  }, [user]);

  const isSpotlightEnabled = useFlag(FeatureFlags.SPOTLIGHT);

  const { showSpotlightDialog, closeSpotlightDialog } = useSpotlightDialog();
  const { getUserSettings } = useSessionUserSettings();

  const handleSpotlightAction = () => {
    sessionContext.spotlight.setSpotlightedFeed(
      isVideoSpotlighted() ? null : props.streamId,
    );
  };

  const handleSpotlightButtonClicked = () => {
    const userSettings = getUserSettings();
    const showDialog = isVideoSpotlighted()
      ? userSettings.spotlight.showConfirmRemoveSpotlightDialog
      : userSettings.spotlight.showConfirmAddSpotlightDialog;
    showDialog
      ? showSpotlightDialog({
          isVideoSpotlighted: isVideoSpotlighted(),
          handleAction: handleSpotlightAction,
          handleClose: closeSpotlightDialog,
        })
      : handleSpotlightAction();
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      height={
        sessionContext.view === SessionFeedView.SPOTLIGHT_VIEW &&
        props.streamId === sessionContext?.spotlight?.spotlightedFeed?.streamId
          ? "100%"
          : "auto"
      }
      style={{
        userSelect: "none",
      }}
    >
      <VideoMediaStyled
        ref={videoContainerRef}
        size={objectFit}
        data-testid={`video-${props.index}`}
      >
        {(!props.connection.stream || isDegraded) && (
          <Box
            width="100%"
            height="100%"
            position="absolute"
            style={{ zIndex: 5, top: 0, left: 0 }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "18px",
              textAlign: "center",
              color: colors.White,
              backgroundColor: colors.BlackMediumEmphasis,
            }}
            data-testid={`spinny-wheel-${props.index}`}
          >
            <div>
              <CircularProgress color="inherit" />
              {isDegraded && (
                <Typography color="WhiteMediumEmphasis" variant="body1">
                  {props.connection.isDcpHost
                    ? "Poor internet connection"
                    : "You or the video owner has a poor internet connection"}
                </Typography>
              )}
            </div>
          </Box>
        )}

        <VideoStyled
          onCanPlay={() => window.dispatchEvent(new Event("resize"))}
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          data-testid={`video-display-${props.index}`}
          data-cy={`video-display-${props.index}`}
          ref={MediaRef}
          onClick={(e) => handleVideoClick(e)}
          controls={false}
          muted={true}
        />
        <sessionContext.ShowForPermission
          permission={Permission.REMOVE_SPOTLIGHTED_FEED}
        >
          <RemoveSpotlightButton
            isVideoSpotlighted={isVideoSpotlighted()}
            handleSpotlightRemoved={handleSpotlightButtonClicked}
          />
        </sessionContext.ShowForPermission>

        {aspectRatio ? (
          <TelestrationCanvas
            containerAspect={containerAspect}
            scale={telestrationScale}
            color={props.color}
            brushWidth={props.brushWidth}
            isDrawingMode={props.isDrawingMode}
            isEraseLineMode={props.isEraseLineMode}
            isCursorMode={props.isCursorMode}
            streamId={props.connection.streamId}
            socket={props.socket}
            aspectRatio={aspectRatio}
            user={props.user}
            sessionParams={props.sessionParams}
            isPtzSelected={props.isPtzSelected}
            selectPTZ={props.selectPTZ}
            activeTab={props.activeTab}
            lastFeedTelestrated={props.lastFeedTelestrated}
            setLastFeedTelestrated={props.setLastFeedTelestrated}
            canUserEditAnnotationWidth={canUserEditAnnotationWidth}
          />
        ) : null}
        {props.ptzUser && (
          <PtzPermissionLabel
            ptzUser={props.ptzUser}
            videoWithMask={props.videoWithMask}
            checkIfVideoWithMasks={props.checkIfVideoWithMasks}
            streamId={props.connection.streamId}
            anchorEl={MediaRef.current}
            boundaryElement={props.videoFeedBoundaryElement}
            variant={props.index >= 3 ? "down" : "up"}
          />
        )}
        <VideoControlsStyled>
          {!!props.ptzUser && (
            <PtzStatusLabel
              label={getCameraLabel(props.connection.params.label)}
              user={props.user}
              ptzUser={props.ptzUser}
              index={props.index}
            />
          )}
          <Fab
            size="small"
            style={{
              minWidth: "40px",
              minHeight: "40px",
            }}
            title="Toggle video menu"
            data-testid={`toggle-video-menu-${props.index}`}
            data-cy={`toggle-video-menu-${props.index}`}
            ref={menuButtonRef}
            onClick={handleClick}
          >
            <MoreVert />
          </Fab>

          <Popover
            id={id}
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            TransitionProps={{
              onExited: () => {
                setTab(VideoMenuTab.main);
              },
            }}
          >
            <Box justifyContent="center">
              {tab === VideoMenuTab.resolution &&
                props.connection.quality?.controls && (
                  <VideoControls
                    onBack={() => setTab(VideoMenuTab.main)}
                    quality={props.connection.quality}
                  />
                )}

              {tab === VideoMenuTab.stats && (
                <VideoStats
                  onBack={() => setTab(VideoMenuTab.main)}
                  deviceName={props.connection.params.label}
                  isOutgoing={props.connection.isOutgoingVideo}
                  quality={props.connection.quality}
                />
              )}

              {tab === VideoMenuTab.main &&
                (isSpotlightEnabled ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <List
                      disablePadding={true}
                      component="nav"
                      data-testid={`video-controls-nav-${props.index}`}
                    >
                      <sessionContext.ShowForPermission
                        permission={Permission.REMOVE_OTHER_PARTICIPANTS_FEED}
                        additionalPredicate={(value) =>
                          value ||
                          props.connection.userId === props.user.profileId ||
                          !!props.connection.isDcpHost
                        }
                      >
                        <ListItemButton
                          onClick={() => {
                            if (props.connection.isOutgoingVideo) {
                              const deviceId = props.connection.stream
                                ?.getVideoTracks()[0]
                                ?.getConstraints().deviceId;
                              deviceId &&
                                props.removeCameraIntent &&
                                props.removeCameraIntent(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (deviceId as any).exact as string,
                                );
                            }
                            props.connection.kick();
                            socketIOWrapper.broadcastAsync(
                              liveApiSockets.MediaSessionEventBroadcastTopics
                                .videoKicked,
                              {
                                kickingUserID: user?.profileId,
                                kickingUserUUID: user?.id,
                                kickingUserRole: role,
                                kickedUserId: props.connection?.userId,
                              },
                            );
                          }}
                          data-cy="close-camera-feed"
                        >
                          <ListItemIcon
                            style={listItemIconStyle}
                            data-cy={`close-video-${props.index}`}
                            data-testid={`close-video-${props.index}`}
                          >
                            <VideocamOff color="error" />
                          </ListItemIcon>
                          <ListItemText primary={"End Video"} />
                        </ListItemButton>
                        <Divider />
                      </sessionContext.ShowForPermission>
                      <sessionContext.ShowForPermission
                        permission={Permission.SPOTLIGHT_FEED}
                      >
                        <ListItemButton
                          data-cy="add-remove-spotlight"
                          disabled={
                            !isVideoSpotlighted() && props.numberOfVideos <= 1
                          }
                          onClick={handleSpotlightButtonClicked}
                        >
                          <ListItemIcon style={listItemIconStyle}>
                            {isVideoSpotlighted() ? (
                              <RemoveSpotlightIcon color="primary" />
                            ) : (
                              <SpotlightIcon color="primary" />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              isVideoSpotlighted()
                                ? "Remove spotlight"
                                : "Spotlight this video"
                            }
                          />
                        </ListItemButton>
                      </sessionContext.ShowForPermission>
                      <ListItemButton
                        data-cy="maximize-minimize-video"
                        data-testid={`video-controls-maximise-${props.index}`}
                        disabled={props.numberOfVideos <= 1}
                        onClick={() => {
                          props.onMaximiseUpdateClick(
                            props.isMaximised ? null : props.streamId,
                          );
                        }}
                      >
                        <ListItemIcon style={listItemIconStyle}>
                          {props.isMaximised ? (
                            <FullscreenExit color="primary" />
                          ) : (
                            <Fullscreen color="primary" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            props.isMaximised
                              ? "Minimize video"
                              : "Maximize video"
                          }
                        />
                      </ListItemButton>
                      {MediaRef.current?.requestPictureInPicture && (
                        <ListItemButton
                          onClick={() => {
                            if (MediaRef.current?.requestPictureInPicture) {
                              MediaRef.current.requestPictureInPicture();
                            }
                          }}
                        >
                          <ListItemIcon style={listItemIconStyle}>
                            <PictureInPicture
                              data-cy={`play-pip`}
                              color="primary"
                            />
                          </ListItemIcon>
                          <ListItemText primary={"Picture in picture"} />
                        </ListItemButton>
                      )}
                      <ListItemButton
                        onClick={() => {
                          setObjectFit((prevFit) => {
                            return prevFit === OBJECT_FIT_COVER
                              ? OBJECT_FIT_CONTAIN
                              : OBJECT_FIT_COVER;
                          });
                        }}
                      >
                        <ListItemIcon style={listItemIconStyle}>
                          {objectFit === OBJECT_FIT_COVER ? (
                            <FitScreenIcon
                              data-testid={`video-controls-object-fit-${props.index}`}
                              color="primary"
                            />
                          ) : (
                            <AspectRatio
                              data-testid={`video-controls-object-fit-${props.index}`}
                              color="primary"
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            objectFit === OBJECT_FIT_COVER
                              ? "Fit video in frame"
                              : "Expand video to fill frame"
                          }
                        />
                      </ListItemButton>
                      <Divider />
                      <sessionContext.ShowForPermission
                        permission={
                          Permission.EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED
                        }
                        additionalPredicate={(value) =>
                          !!props.connection.params?.capabilities?.canMask &&
                          (value || props.connection.isOutgoingVideo)
                        }
                      >
                        <ListItemButton
                          onClick={() => {
                            props.editPrivacyMask?.(props.streamId);
                            setAnchorEl(null);
                          }}
                          data-testid="video-pii-edit-mask"
                        >
                          <ListItemIcon style={listItemIconStyle}>
                            <SecurityIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={"Edit Privacy Control"} />
                        </ListItemButton>
                      </sessionContext.ShowForPermission>
                      {!!props.ptzUser && (
                        <ListItemButton
                          onClick={() => {
                            props.selectPTZ?.(props.streamId);
                            props.setActiveTab(ActiveTab.PTZPANEL);
                            setAnchorEl(null);
                          }}
                        >
                          <ListItemIcon style={listItemIconStyle}>
                            <OpenWith color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={"Open PTZ Panel"} />
                        </ListItemButton>
                      )}
                      <Divider />
                      {!!props.connection.quality?.controls && (
                        <ListItemButton
                          onClick={() => {
                            setTab(VideoMenuTab.resolution);
                          }}
                        >
                          <ListItemIcon style={listItemIconStyle}>
                            <Settings color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={"Video quality"} />
                          <ChevronRight
                            width={"20px"}
                            height={"20px"}
                            sx={{ color: colors.Grey600 }}
                          />
                        </ListItemButton>
                      )}
                      <ListItemButton
                        onClick={() => {
                          setTab(VideoMenuTab.stats);
                        }}
                      >
                        <ListItemIcon style={listItemIconStyle}>
                          <Info color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={"Video information"} />
                        <ChevronRight
                          width={"20px"}
                          height={"20px"}
                          sx={{ color: colors.Grey600 }}
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                ) : (
                  // TODO: cleanup duplicated code when feature flag is fully released
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <List
                      component="nav"
                      data-testid={`video-controls-nav-${props.index}`}
                    >
                      {!!props.connection.quality?.controls && (
                        <ListItem
                          button
                          onClick={() => {
                            setTab(VideoMenuTab.resolution);
                          }}
                        >
                          <Tooltip
                            title="Toggle video controls"
                            placement="left"
                          >
                            <IconButton size="small" color="primary">
                              <Settings />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      )}
                      {!!props.connection.quality?.stats && (
                        <ListItem
                          button
                          onClick={() => {
                            setTab(VideoMenuTab.stats);
                          }}
                        >
                          <Tooltip title="Toggle stats view" placement="left">
                            <IconButton size="small" color="primary">
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      )}
                      <sessionContext.ShowForPermission
                        permission={Permission.REMOVE_OTHER_PARTICIPANTS_FEED}
                        additionalPredicate={(value) =>
                          value ||
                          props.connection.userId === props.user.profileId ||
                          !!props.connection.isDcpHost
                        }
                      >
                        <ListItem
                          button
                          onClick={() => {
                            if (props.connection.isOutgoingVideo) {
                              const deviceId = props.connection.stream
                                ?.getVideoTracks()[0]
                                ?.getConstraints().deviceId;
                              deviceId &&
                                props.removeCameraIntent &&
                                props.removeCameraIntent(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (deviceId as any).exact as string,
                                );
                            }
                            props.connection.kick();
                            socketIOWrapper.broadcastAsync(
                              liveApiSockets.MediaSessionEventBroadcastTopics
                                .videoKicked,
                              {
                                kickingUserID: user?.profileId,
                                kickingUserUUID: user?.id,
                                kickingUserRole: role,
                                kickedUserId: props.connection?.userId,
                              },
                            );
                          }}
                          data-cy="close-camera-feed"
                        >
                          <Tooltip title="Close video" placement="left">
                            <IconButton
                              size="small"
                              data-cy={`close-video-${props.index}`}
                              data-testid={`close-video-${props.index}`}
                              color="primary"
                            >
                              <VideocamOff />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      </sessionContext.ShowForPermission>
                      {!!props.ptzUser && (
                        <ListItem
                          button
                          onClick={() => {
                            props.selectPTZ?.(props.streamId);
                            props.setActiveTab(ActiveTab.PTZPANEL);
                            setAnchorEl(null);
                          }}
                        >
                          <Tooltip title="Open PTZ Panel" placement="left">
                            <IconButton size="small" color="primary">
                              <OpenWith />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      )}
                      <ListItem
                        button
                        onClick={() => {
                          props.onMaximiseUpdateClick(
                            props.isMaximised ? null : props.streamId,
                          );
                        }}
                        disabled={props.numberOfVideos <= 1}
                      >
                        <Tooltip
                          title={
                            props.isMaximised
                              ? "Minimize feed"
                              : "Maximize feed"
                          }
                          placement="left"
                        >
                          <IconButton
                            data-testid={`video-controls-maximise-${props.index}`}
                            size="small"
                            color="primary"
                          >
                            {props.isMaximised ? (
                              <FullscreenExit />
                            ) : (
                              <Fullscreen />
                            )}
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          setObjectFit((prevFit) => {
                            return prevFit === OBJECT_FIT_COVER
                              ? OBJECT_FIT_CONTAIN
                              : OBJECT_FIT_COVER;
                          });
                        }}
                      >
                        <Tooltip
                          title={
                            objectFit === OBJECT_FIT_CONTAIN
                              ? "Expand video to fill available space"
                              : "Show full video"
                          }
                          placement="left"
                        >
                          <IconButton
                            data-testid={`video-controls-object-fit-${props.index}`}
                            size="small"
                            color="primary"
                          >
                            <AspectRatio />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                      {MediaRef.current?.requestPictureInPicture ? (
                        <ListItem
                          button
                          onClick={() => {
                            if (MediaRef.current?.requestPictureInPicture) {
                              MediaRef.current.requestPictureInPicture();
                            }
                          }}
                        >
                          <Tooltip
                            title="Play video picture in picture"
                            placement="left"
                          >
                            <IconButton
                              size="small"
                              data-cy={`play-pip`}
                              color="primary"
                            >
                              <PictureInPicture />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      ) : null}
                      <sessionContext.ShowForPermission
                        permission={
                          Permission.EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED
                        }
                        additionalPredicate={(value) =>
                          !!props.connection.params?.capabilities?.canMask &&
                          (value || props.connection.isOutgoingVideo)
                        }
                      >
                        <ListItem
                          button
                          onClick={() => {
                            props.editPrivacyMask?.(props.streamId);
                            setAnchorEl(null);
                          }}
                          data-testid="video-pii-edit-mask"
                        >
                          <Tooltip
                            title="Edit Privacy Control"
                            placement="left"
                          >
                            <IconButton size="small" color="primary">
                              <SecurityIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      </sessionContext.ShowForPermission>
                    </List>
                  </Box>
                ))}
            </Box>
          </Popover>
        </VideoControlsStyled>
      </VideoMediaStyled>
    </Box>
  );
};

export default Video;
