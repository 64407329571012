import { Button, styled } from "@mui/material";

export const OrganisationMenuToggleStyled = styled(Button)(
  ({ theme }) => `
  max-width: 300px;
  color: ${theme.palette.grey[800]};
  border-color: ${theme.colors.Black};

  .button-copy {
    font-size: inherit;
    line-height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`,
);
