import { ExceptionCodes } from "../exception-codes";

export interface ErrorsDetail {
  message: string;
  code: ExceptionCodes;
  meta: Record<string, unknown>;
}

export interface HandleErrorsResponseData {
  errors: ErrorsDetail[];
  correlationId?: string;
}
