import React, { useEffect, useState } from "react";
import { PARTICIPANT_STATUS } from "../constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import Participant from "../Participant/Participant";
import SidebarPanel from "../SidebarPanel/SidebarPanel";
import { ParticipantProps } from "@proximie/components";
import {
  SessionParticipant,
  useSessionParticipants,
} from "../../hooks/useSessionParticipants";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { Permission } from "../../utils/checkPermission";
import { Member, Session, user } from "@proximie/api";

interface ParticipantsListProps {
  audioParticipants: ParticipantProps[];
  members?: Member[];
  session?: Session;
  // eslint-disable-next-line @typescript-eslint/ban-types
  inviteUser: Function;
  muteAll: () => Promise<void>;
  setMuteState: (streamId: string, isMuted: boolean) => Promise<void>;
  user?: user.User;
  closePanel: () => void;
}

const ParticipantsList = ({
  audioParticipants,
  members,
  session,
  inviteUser,
  muteAll,
  setMuteState,
  user,
  closePanel,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ParticipantsListProps): JSX.Element => {
  const [searchText, setSearchText] = useState("");
  const [inThisSessionList, setInThisSessionList] = useState<
    ParticipantProps[]
  >([]);
  const [otherInvitedList, setOtherInvitedList] = useState<
    ParticipantProps[] | undefined
  >([]);
  const [notInvitedList, setNotInvitedList] = useState<Member[] | undefined>(
    [],
  );

  const { sessionParticipants } = useSessionParticipants(session?.id);
  const { ShowForPermission } = useSessionContext();

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const getName = <T extends Member | ParticipantProps>(participant: T) => {
    return (
      (participant.name?.trim() || null) ??
      (participant.email ? maskEmail(participant.email) : null) ??
      "N/A"
    );
  };

  const maskEmail = (email: string) => {
    if (!email) return email;
    return `${email.substring(0, 3)}***@***...`;
  };

  const filterFunc = (user: { name?: string; email?: string }): boolean => {
    if (user.name)
      return user.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    else if (user.email)
      return user.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    else return false;
  };

  useEffect(() => {
    const filterBySearchText = <T extends Member | SessionParticipant>(
      list?: T[],
    ) => {
      return list?.filter(filterFunc);
    };

    if (Array.isArray(sessionParticipants)) {
      setNotInvitedList(
        filterBySearchText(
          members?.filter((member) => {
            member.email = maskEmail(member.email);
            return (
              sessionParticipants.filter((subUser) => subUser.id === member?.id)
                .length === 0
            );
          }),
        ),
      );
    } else {
      setNotInvitedList(filterBySearchText(members));
    }

    if (Array.isArray(sessionParticipants)) {
      setOtherInvitedList(
        filterBySearchText(
          sessionParticipants.filter(
            (user) =>
              !audioParticipants.some(
                (subUser) => subUser.userUUID === user.id,
              ),
          ),
        )?.map((participant, index) => ({
          id: index,
          name: participant.name,
          userUUID: participant.id,
          role: participant.role,
          metadata: participant.metadata,
          streamId: participant.streamId,
          email: participant.email,
        })),
      );
    } else {
      setOtherInvitedList([]);
    }

    setInThisSessionList(
      audioParticipants
        .map((p): ParticipantProps => {
          if (Array.isArray(sessionParticipants)) {
            const sessionParticipant = sessionParticipants?.find(
              (sp) =>
                sp.id === p.userUUID ||
                //LATER - for backwards compatibility
                sp.profileId === p.id,
            );
            p.role = sessionParticipant?.role;
            p.name = sessionParticipant?.name ?? "";
            p.email = sessionParticipant?.email;
          }
          return p;
        })
        .filter(filterFunc),
    );
  }, [audioParticipants, members, searchText, sessionParticipants, user?.id]);

  const handleMuteAllClick = () => {
    muteAll();
  };
  return (
    <SidebarPanel title="Participants" closePanel={closePanel}>
      <ShowForPermission permission={Permission.MUTE_ALL}>
        <Box pt={1} pb={2} px={2}>
          <Button
            fullWidth
            variant="contained"
            color={"secondary"}
            onClick={handleMuteAllClick}
            data-testid={"mute-all-btn"}
          >
            Mute All
          </Button>
        </Box>
      </ShowForPermission>

      <Box py={1} px={2}>
        <TextField
          size="small"
          label="Search participants"
          fullWidth
          value={searchText}
          onChange={handleTextFieldChange}
          data-testid={"participants-search-box"}
          InputProps={{
            endAdornment:
              searchText.length > 0 ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </Box>

      <Box
        flex={1}
        style={{ overflowY: "scroll" }}
        data-testid="participants-list"
      >
        <Accordion
          defaultExpanded
          disableGutters
          data-testid="inThisSessionList"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              In this session ({inThisSessionList.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {inThisSessionList.map((participant) => (
              <Participant
                {...participant}
                name={getName(participant)}
                setMuteState={(mute: boolean) =>
                  participant.streamId &&
                  setMuteState(participant.streamId, mute)
                }
                key={"joined" + participant.streamId}
                userStatus={PARTICIPANT_STATUS.Online}
                role={participant.role}
                isMe={user?.id === participant.userUUID}
                isLocal={participant.isLocal}
                isMuted={participant.isMuted}
                data-testid={`in-this-session-` + participant.id}
              />
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters data-testid="othersInvitedList">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Others invited ({otherInvitedList?.length || 0})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {otherInvitedList?.map((participant) => (
              <Participant
                name={getName(participant)}
                key={"others" + participant.id}
                inviteUser={() => inviteUser(participant.userUUID)}
                userStatus={PARTICIPANT_STATUS.Invited}
                isMe={false}
                role={participant.role}
                data-testid={`invited-` + participant.id}
              />
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters data-testid="notInvitedList">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Not invited ({notInvitedList?.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {notInvitedList?.map((participant) => (
              <Participant
                name={getName(participant)}
                key={"notInvited" + participant.id}
                inviteUser={() => inviteUser(participant.id)}
                userStatus={PARTICIPANT_STATUS.NotInvited}
                isMe={user?.id === participant.id}
                data-testid={`not-invited-` + participant.id}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </SidebarPanel>
  );
};
export default ParticipantsList;
